import type { CatchBullGame } from './catchTheBull'

export class Explosion {
  game: CatchBullGame
  size: number
  x: number
  y: number
  frameX: number
  frames: number
  fps: number
  timer: number
  interval: number
  needToRemove: boolean
  img: any

  constructor(game: CatchBullGame, x: number, y: number) {
    this.game = game
    this.size = 200
    this.x = x - this.size * 0.5
    this.y = y - this.size * 0.5
    this.frameX = 0
    this.frames = 8
    this.fps = 50
    this.timer = 0
    this.interval = 1000 / this.fps
    this.needToRemove = false
    this.img = document.getElementById('explosion')
  }
  update(deltaTime: number) {
    if (this.timer > this.interval) {
      this.frameX++
      this.timer = 0
    } else {
      this.timer += deltaTime
    }
    if (this.frameX > this.frames) this.needToRemove = true
  }
  draw(ctx: CanvasRenderingContext2D) {
    ctx.drawImage(
      this.img,
      this.size * this.frameX,
      0,
      this.size,
      this.size,
      this.x + this.size * 0.25,
      this.y + this.size * 0.25,
      this.size * 0.5,
      this.size * 0.5
    )
  }
}
