<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    align?: 'start' | 'center' | 'end'
    big?: boolean
    block?: boolean
    paddingX?: number | null
    yellow?: boolean
    disabled?: boolean
  }>(),
  {
    align: 'center',
    big: false,
    block: false,
    paddingX: null,
    disabled: false
  }
)

const styles = computed(() => ({
  padding: props.big ? '18px 10px' : '',
  'font-size': props.big ? '13px' : '15px',
  'line-height': props.big ? 1.54 : 1.33,
  width: props.block ? '100%' : '',
  'padding-left': props.paddingX ? `${props.paddingX}px` : '',
  'padding-right': props.paddingX ? `${props.paddingX}px` : '',
  'text-align': props.align,
  opacity: props.disabled ? 0.5 : 1
}))
</script>

<template>
  <div class="flex items-center" :style="{ 'justify-content': `${align}` }">
    <div class="btn" :class="{ yellow }" :style="styles">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.btn {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.43px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 12px;
  background: linear-gradient(123deg, #d1ffa6 6.07%, #71be2b 82.15%);
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.2) inset,
    0px 2px 4px 0px rgba(0, 0, 0, 0.12);

  color: var(--black, #0d0e0c);
  font-family: Urbanist;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px 16px;
  transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.btn.yellow {
  background: linear-gradient(123deg, #ffd 6.07%, #fcff2d 82.15%);
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.2) inset,
    0px 2px 4px 0px rgba(0, 0, 0, 0.12);
}
</style>
