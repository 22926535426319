import {
  getActiveEvent,
  getActiveEventPhase,
  getPhaseUsers as getPhaseUsersApi,
  getUserRegistrationPhase,
  registerForPhase as registerUserForPhase
} from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

type EventPhase = {
  id: number
  started_at: Date
  ended_at: Date
  is_registered: boolean
  credits_earned: number | undefined
  can_register_after_registration_time_passed: boolean
}

type RegistrationPhase = EventPhase
export type PhaseUsers = { id: number; name: string; balance: number; profile_image_url: string }
export type TapEventCategory = { id: number; bid: number; title: string }

export const EVENT_REGISTRATION_ID = 10
export const EVENT_RESULTS_ID = 12

export const useGameEventsStore = defineStore('gameEvents', () => {
  const tapEventCategories = ref<TapEventCategory[]>([])
  const isUserRegisteredForEvent = ref(false)
  const registrationPhase = ref<RegistrationPhase | null>(null)
  const activeEventPhase = ref<EventPhase | null>(null)
  const phaseUsers = ref<PhaseUsers[]>([])
  const fetchingResults = ref(true)
  const eventId = ref(4)

  const hasNewEvent = computed(() =>
    activeEventPhase.value ? !activeEventPhase.value?.is_registered : false
  )

  async function checkUserRegistration(eventId: number, phaseId: number) {
    // registrationPhase.value = await getUserRegistrationPhase(eventId, phaseId)

    // isUserRegisteredForEvent.value = registrationPhase.value?.is_registered ||
    isUserRegisteredForEvent.value = false
  }

  function registerForPhase(phaseId: number) {
    registerUserForPhase(phaseId).then((registration) => {
      if (registration?.success) {
        isUserRegisteredForEvent.value = true
        if (activeEventPhase.value) activeEventPhase.value.is_registered = true
      }
    })
  }

  async function getCurrentEventPhase(eventId: number) {
    activeEventPhase.value = (await getActiveEventPhase(eventId)) as EventPhase | null
  }

  async function getCurrentEvent() {
    await getActiveEvent().then((event) => {
      eventId.value = event.id
      getCurrentEventPhase(event.id)
    })
  }

  async function getPhaseUsers(phaseId: number) {
    fetchingResults.value
    try {
      phaseUsers.value = await getPhaseUsersApi(phaseId)
    } catch (error) {
      // phaseUsers.value = [
      //   { id: 1, name: 'name-1', balance: 2545, profile_image_url: '' },
      //   { id: 2, name: 'name-2', balance: 355, profile_image_url: '' },
      //   { id: 3, name: 'name-3', balance: 34634, profile_image_url: '' },
      //   { id: 4, name: 'name-4', balance: 254564345, profile_image_url: '' }
      // ]
    } finally {
      fetchingResults.value = false
    }
  }

  async function getCategories() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          { id: 1, bid: 100, title: '100 stars (friends invites applicable)' },
          { id: 2, bid: 500, title: '500 stars (friends invites applicable)' },
          { id: 3, bid: 1000, title: '1000 stars (friends invites not applicable)' },
          { id: 4, bid: 5000, title: '5000 stars (friends invites not applicable)' }
        ])
      }, 1000)
    }).then((categories) => {
      tapEventCategories.value = [...(categories as any)]
    })
  }

  const isRegistrationAvailable = computed(() => true)

  const isEventPhase = computed(() => activeEventPhase.value?.id == EVENT_RESULTS_ID)

  const infoBannerTitle = computed(() => {
    if (isRegistrationAvailable.value) return 'homeTab.info.newEvent'
    if (isEventPhase.value) return 'homeTab.info.started'
    return 'homeTab.info.stayTuned'
  })

  const infoBannerSubtitle = computed(() => {
    if (isRegistrationAvailable.value) return ''
    if (isEventPhase.value) return ''
    return 'homeTab.info.newEventsAreComing'
  })

  const infoBannerTimerText = computed(() => {
    if (isRegistrationAvailable.value) return 'homeTab.info.startsIn'
    if (isEventPhase.value) return 'homeTab.info.endsIn'
    return ''
  })
  // activeEventPhase.value?.id == 12)

  const infoBannerTimerDate = computed(() => {
    if (isRegistrationAvailable.value) return activeEventPhase.value?.ended_at
    if (isEventPhase.value) return activeEventPhase.value?.ended_at

    return undefined
  })

  return {
    isUserRegisteredForEvent,
    checkUserRegistration,
    registrationPhase,
    registerForPhase,
    getCurrentEvent,
    getCurrentEventPhase,
    activeEventPhase,
    getPhaseUsers,
    phaseUsers,
    hasNewEvent,
    eventId,
    fetchingResults,
    getCategories,
    tapEventCategories,
    isRegistration: isRegistrationAvailable,
    isEventPhase,
    infoBannerTitle,
    infoBannerSubtitle,
    infoBannerTimerText,
    infoBannerTimerDate
  }
})
