<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ActionBtn from '../common/ActionBtn.vue'
import BtnPrimary from '../common/BtnPrimary.vue'
import Container from '../common/Container.vue'
import DialogModal from '../common/DialogModal.vue'
import EventTimer from '../common/EventTimer.vue'
import ExpansionPanel from '../common/ExpansionPanel.vue'
import InfoBanner from '../common/InfoBanner.vue'
import ThreeRowsCard from '../common/ThreeRowsCard.vue'
import IconTicketStar from '../icons/IconTicketStar.vue'
import FreeTicket from './FreeTicket.vue'
import HowRaffleWorks from './HowRaffleWorks.vue'
import RaffleAmount from './RaffleAmount.vue'

import { useRaffleStore } from '@/stores/raffles'
import WebApp from '@twa-dev/sdk'
import { storeToRefs } from 'pinia'
import SpecialOfferBanner from '../common/SpecialOfferBanner.vue'

const raffleStore = useRaffleStore()
const { getCurrentRaffle } = raffleStore
const {
  userTicketsCount,
  prizePool,
  raffleFinishDate,
  availableTickets,
  unusedTotalStarsCashback
} = storeToRefs(raffleStore)

// const tonBalance = ref(5000)
const freeTicketProgress = ref(true)
const buyTicketsModal = ref(false)

const { t } = useI18n()

const hasSpecialOffer = computed(() => availableTickets.value.some((el) => el.special_cost))

const buyTickets = () => {
  buyTicketsModal.value = true
}
const closeBuyTicketsModal = () => {
  buyTicketsModal.value = false
}

const buyTicket = (invoiceLink: string) => {
  WebApp.openInvoice(invoiceLink, (status) => {
    if (status === 'paid') {
      setTimeout(async () => {
        await getCurrentRaffle()
      }, 5000)
    }
  })
}

onMounted(async () => {
  await getCurrentRaffle()
})
</script>

<template>
  <div class="tickets">
    <div style="max-width: 95%" class="ma-auto">
      <h2 class="mt-12 page-title yellow" style="font-size: 24px">
        {{
          t('raffleTab.title', {
            participants: 100,
            stars: prizePool
          })
        }}
      </h2>
    </div>

    <div style="max-width: 70%" class="ma-auto">
      <h3 class="page-sub-title mt-5">
        {{ t('raffleTab.description') }}
      </h3>
    </div>

    <InfoBanner class="mt-7" :title="t('raffleTab.raffleEndsIn')" yellow>
      <template #actions>
        <BtnPrimary :padding-x="30" yellow>
          <EventTimer red :size="16" :end-date="new Date(raffleFinishDate)" hide-seconds />
        </BtnPrimary>
      </template>
    </InfoBanner>

    <FreeTicket v-if="freeTicketProgress" class="mt-12" :starsCashback="unusedTotalStarsCashback" />

    <RaffleAmount class="mt-4" :value="userTicketsCount" />

    <ExpansionPanel class="mt-8" more-btn-text="raffleTab.wantMoreTickets" hide-less-btn>
      <HowRaffleWorks class="mt-12" />
    </ExpansionPanel>

    <div class="ticketsBtn-container">
      <Container>
        <ActionBtn yellow :text="t('raffleTab.buyTickets')" @click="buyTickets">
          <template #append-icon>
            <IconTicketStar style="width: 24px; height: 24px" />
          </template>
        </ActionBtn>
      </Container>
    </div>

    <DialogModal
      v-if="buyTicketsModal"
      @close="closeBuyTicketsModal"
      :title="t('raffleTab.buyTickets')"
      yellow
    >
      <!-- specialOffer  -->
      <SpecialOfferBanner v-if="hasSpecialOffer" />

      <div class="earn-container">
        <ThreeRowsCard
          v-for="ticket in availableTickets"
          :key="`ticket-${ticket.id}`"
          :title="`x${ticket.quantity}`"
          :cost="ticket.stars_cost"
          :discount="ticket.special_cost"
          @click="buyTicket(ticket.url)"
          yellow
        >
          <template #icon="{ _class }">
            <IconTicketStar
              class="text-primary"
              :class="_class"
              style="width: 32px; height: 32px"
            />
          </template>
        </ThreeRowsCard>
      </div>
    </DialogModal>
  </div>
</template>

<style scoped>
.tickets {
  padding-bottom: 110px;
}
.ticketsBtn-container {
  position: fixed;
  z-index: 99;
  bottom: 112px;
  left: 0;
  right: 0;
}
.ticket-big {
  width: 146px;
  height: 146px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--card-yellow-bg);
  border: 1px solid var(--card-yellow-border);
  border-radius: 16px;
  padding: 8px;
  gap: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  color: white;
}
.ticket-big-title {
  color: var(--white);
  font-family: Urbanist;
  font-size: 19.909px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
}
.ticket-big-value {
  color: var(--yellow);
  font-family: Urbanist;
  font-size: 17.255px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.086px;
}
</style>
