import type { CatchBullGame } from '../catchTheBull.js'
import { BaseBull } from './bull.js'

export class Bull4 extends BaseBull {
  constructor(game: CatchBullGame, x: number, y: number, col: number, lifeTime: number) {
    super(game, x, y, col, lifeTime)

    this.img = document.getElementById('bull-4')
  }
}
