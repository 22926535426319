<script setup lang="ts">
import { EVENT_REGISTRATION_ID, EVENT_RESULTS_ID, useGameEventsStore } from '@/stores/gameEvents'
import { storeToRefs } from 'pinia'
import { computed, onMounted } from 'vue'
import ComingSoon from '../common/ComingSoon.vue'
import Container from '../common/Container.vue'
import EventTab from '../event/Event.vue'
import Registration from './Registration.vue'
import Results from './Results.vue'

const userEventStore = useGameEventsStore()
const { getCurrentEventPhase } = userEventStore
const { activeEventPhase, eventId } = storeToRefs(userEventStore)

const currentEventPhase = computed(() => {
    return Registration
//   if (!activeEventPhase.value) return null

//   switch (activeEventPhase.value?.id) {
//     case EVENT_REGISTRATION_ID:
//       return Registration
//     case 11:
//       return EventTab
//     case EVENT_RESULTS_ID:
//       return Results
//     default:
//       return Registration
//   }
})

const componentForNonRegistered = computed(() => {
    return Registration
//   switch (activeEventPhase.value?.id) {
//     case EVENT_REGISTRATION_ID:
//     case 11:
//       return Registration
//     case EVENT_RESULTS_ID:
//       return Results
//     default:
//       return Registration
//   }
})

onMounted(async () => {
  await getCurrentEventPhase(eventId.value)
})
</script>

<template>
  <Container>
    <!-- <div v-if="currentEventPhase !== null">
      <ComingSoon />
    </div>
    <component v-else-if="activeEventPhase?.is_registered" :is="currentEventPhase" />
    <component v-else :is="componentForNonRegistered" /> -->
    <component v-if="false" :is="currentEventPhase" />
    <component v-else :is="componentForNonRegistered" />
  </Container>
</template>

<style scoped></style>
