<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Container from '../common/Container.vue'
import FullWindowModal from '../common/FullWindowModal.vue'
import ListItem from '../common/ListItem.vue'
import IconBack from '../icons/IconBack.vue'
import IconCheck from '../icons/IconCheck.vue'
import IconCupSolid from '../icons/IconCupSolid.vue'
import WinnersList from './WinnersList.vue'

const { t } = useI18n()

const openWinnersList = ref(false)
const winnersByEventId = ref<any>([])
const selectedHistory = ref<number | null>(null)

const history = ref([{ id: 1, title: 'Tournament 1', dateStart: '23/12/24', dateEnd: '29/12/24' }])

const getWinnersByEventId = (id: number) => {
  console.log('getWinnersByEventId: ', id)

  // api
  winnersByEventId.value = [
    { id: 1, name: 'User 1', profile_image_url: '', balance: 100, profit: 0, rank_id: 2 }
  ]
}

const selectHistory = (id: number) => {
  selectedHistory.value = id
  getWinnersByEventId(id)
  openWinnersList.value = true
}

// History → should use the layout similar to Category page, but display tournaments with the dates it took place (i.e. 23/12/24-29/12/24). Clicking each tournament in History will show the table of Winners and their results.
</script>

<template>
  <div>
    <div class="ma-auto" style="max-width: 80%">
      <h2 class="mt-4 page-title" v-html="t('eventTab.categories.title')"></h2>
      <h3 class="page-sub-title mt-5">{{ t('eventTab.categories.description') }}</h3>
    </div>

    <!-- categories list  -->
    <div v-if="history?.length > 0" class="list-container mt-8">
      <ListItem
        v-for="item in history"
        :key="item.id"
        :title="item.title"
        @click.stop="selectHistory(item.id)"
      >
        <template #prepend>
          <IconCupSolid
            class="text-dark-green"
            style="width: 48px; height: 48px; max-width: 48px"
          />
        </template>
        <template #subtitle>
          <div class="subtitle">{{ item.dateStart }} - {{ item.dateEnd }}</div>
        </template>
        <template #append>
          <IconCheck v-if="false" style="width: 25px; height: 25px" />
        </template>
      </ListItem>
    </div>
    <ListItem v-else class="mt-8">
      {{ t('eventTab.emptyEventHistory') }}
    </ListItem>

    <FullWindowModal v-if="openWinnersList">
      <Container>
        <div class="flex justify-between items-center mt-6">
          <IconBack
            class="text-light-green"
            style="width: 20px; height: 20px"
            @click="openWinnersList = false"
          />
        </div>

        <div class="overflowOn">
          <WinnersList :users="winnersByEventId" style="margin-top: 24px" />
        </div>
      </Container>
    </FullWindowModal>
  </div>
</template>
