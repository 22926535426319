<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

interface Props {
  justify?: 'center' | 'left' | 'right'
  tabs: { id: number; title: string }[]
  tab: string
}

const { justify } = withDefaults(defineProps<Props>(), { justify: 'center', tabs: () => [] })
defineEmits(['update:tab'])

const { t } = useI18n()

const styles = computed(() => ({
  'justify-content': justify
}))
</script>

<template>
  <div class="tabs" :style="styles">
    <div class="tab-container">
      <div
        class="tab"
        v-for="tabItem in tabs"
        :key="tabItem.id"
        :class="{ active: tab == tabItem.title }"
        @click="$emit('update:tab', tabItem.title)"
      >
        {{ t(tabItem.title) }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.tabs {
  display: flex;
  align-items: center;
}
.tab-container {
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  box-sizing: border-box;
  background: var(--black, #0d0e0c);
  background-clip: padding-box; /* !importanté */
  border: solid 2px transparent; /* !importanté */
  border-radius: 12px;
}
.tab-container:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px; /* !importanté */
  border-radius: inherit; /* !importanté */
  background: linear-gradient(90deg, var(--primary), rgba(177, 247, 112, 0.46));
  /* background: linear-gradient(90deg, var(--primary), #314222); */
}
.tab {
  color: #76a24b;
  font-weight: 300;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  padding: 6px 22px;
  border-radius: 8px;
  transition: all 0.4s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  border: 1px solid #7baa4e;
}
.tab.active {
  color: var(--white);
  border: 1px solid #5c7b3d;
  background: #5c7b3d;
  font-weight: 500;
}
</style>
