import { type CatchBullGame } from './catchTheBull'
import { GAME_ELEMENT_WIDTH } from './consts'

export class Gate {
  game: CatchBullGame
  x: number
  y: number
  row: number
  width: number
  height: number

  // frames = 38
  frameX = 0
  frameY = 0
  frameWidth = 228
  frameHeight = 169
  img: any
  // fps: number
  // timer: number
  // interval: number
  // checkingCollision: boolean
  isOpen: boolean

  constructor(game: CatchBullGame, x: number, row: number) {
    this.game = game
    this.x = x
    this.row = row
    this.width = GAME_ELEMENT_WIDTH
    this.height = GAME_ELEMENT_WIDTH
    this.y = this.row * this.height //-10 * this.row

    // this.frames = 38
    this.frameX = 0
    this.frameY = 0
    this.frameWidth = 453
    this.frameHeight = 265
    this.img = document.getElementById('gate')
    // this.score = this.health
    // this.fps = 1
    // this.timer = 0
    // this.interval = lifeTime / this.fps
    // this.checkingCollision = false
    this.isOpen = false
  }
  update() {
    if (this.isOpen) {
      this.frameY = 1
    } else {
      this.frameY = 0
    }
  }
  draw(ctx: CanvasRenderingContext2D) {
    ctx.drawImage(
      this.img,
      0,
      this.frameHeight * this.frameY,
      this.frameWidth,
      this.frameHeight,
      this.x,
      this.y + this.height * 0.3,
      this.width,
      this.height * 0.7
    )
  }
}
