<script setup lang="ts">
import { useTournamentStore, type TournamentCurrencyRound } from '@/stores/tournament'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import Loader from '../common/Loader.vue'
import IconBack from '../icons/IconBack.vue'
// import RoundWidget from './RoundWidget.vue'
import { useGlobalIntervalStore } from '@/stores/globalInterval'
import CardWithBgGradient from '../common/CardWithBgGradient.vue'
import EventTimer from '../common/EventTimer.vue'
import CategoryGroupWidgetWithTimer from './CategoryGroupWidgetWithTimer.vue'

interface Props {
  categoryId: number
}

type Tournament = TournamentCurrencyRound

const props = defineProps<Props>()
const emit = defineEmits(['close', 'select-round'])

const gettingCategory = ref(true)
const tryCount = ref(0)
const now = ref(new Date())

const { t } = useI18n()

const tournamentStore = useTournamentStore()
const { getTournamentById, getTournamentCategoryById, pushCurrencyRound } = tournamentStore
const { tournament, tournamentCurrencyRounds } = storeToRefs(tournamentStore)

const isDayTournament = computed(() => props.categoryId == 4)

const tournamentCurrencyRoundsGroups = computed(() => {
  // if not day tournament return one group
  if (!isDayTournament.value) return [tournamentCurrencyRounds.value.slice(0, 4)]

  //  else split on groups by start date
  const roundObjectByStartDate = tournamentCurrencyRounds.value.reduce((acc: any, cur) => {
    if (acc[cur.registration_starts_at as any]) {
      acc[cur.registration_starts_at as any].push(cur)
    } else {
      acc[cur.registration_starts_at as any] = [cur]
    }
    return acc
  }, {})

  const resArr: any[] = []
  Object.keys(roundObjectByStartDate)
    .sort((a: any, b: any) => Date.parse(a) - Date.parse(b))
    .forEach((key) => {
      resArr.push(roundObjectByStartDate[key])
    })

  return resArr
})

const round = computed(() =>
  tournamentCurrencyRounds.value?.length
    ? tournamentCurrencyRounds.value[0]
    : {
        registration_starts_at: new Date(),
        started_at: new Date(),
        ended_at: new Date(),
        id: 1
      }
)
const isPreRegistration = computed(() => now.value < round.value.registration_starts_at)
const isRegistration = computed(
  () => round.value.registration_starts_at <= now.value && now.value < round.value.started_at
)
const isInProgress = computed(
  () => round.value.started_at <= now.value && now.value < round.value.ended_at
)
const isEnded = computed(() => round.value.ended_at < now.value)

const currentEndDate = computed(() => {
  if (isRegistration.value) return round.value.started_at
  if (isInProgress.value) return round.value.ended_at
  return round.value.registration_starts_at
})

const { tick } = storeToRefs(useGlobalIntervalStore())

watch(tick, () => {
  now.value = new Date()
})

const selectTournament = (_tournament: Tournament) => {
  emit('select-round', {
    ..._tournament,
    categoryTitle: tournament.value?.title,
    categoryId: props.categoryId
  })
}

onMounted(async () => {
  try {
    if (tournament.value?.id !== props.categoryId)
      await Promise.all([
        getTournamentById(props.categoryId),
        getTournamentCategoryById(props.categoryId)
      ])

    if (tournament.value && tournament.value.id === 4) {
      setupNewTournamentsListener()
    }
  } catch (error) {
    //
  } finally {
    gettingCategory.value = false
  }
})

const renewRounds = () => {
  if (tryCount.value > 3) return

  if (isEnded.value) {
    nextTick(async () => {
      try {
        await getTournamentCategoryById(props.categoryId)
        tryCount.value = 0
      } catch (e) {
        tryCount.value += 1
        //
      }
    })
  }
}

function setupNewTournamentsListener() {
  //   console.log('setupNewTournamentsListener')

  window.Echo.channel('prediction_tournament_currency_rounds').listen(
    'TournamentCurrencyRoundCreated',
    async (event: {
      round: App.DTO.PredictionTournament.PredictionTournamentCurrencyRoundData
    }) => {
      console.log('TournamentCurrencyRoundCreated', event)

      await pushCurrencyRound(event.round)
    }
  )
}
</script>

<template>
  <div v-if="tournament === null" class="flex justify-center">
    <Loader />
  </div>
  <div v-else>
    <div class="mt-10 relative">
      <div class="flex justify-center flex-col items-center gap-2 ma-auto" style="max-width: 80%">
        <h2 class="page-title" v-html="t(tournament.title)"></h2>

        <h3 class="page-sub-title mt-4">
          {{ t(tournament.description) }}
        </h3>
      </div>
      <IconBack class="back-icon" @click="$emit('close')" />
    </div>

    <template v-if="!isDayTournament">
      <div class="text-center mt-2 mb-2" v-if="isPreRegistration">
        <div class="timer-title">
          {{ t('tournamentTab.tournaments.registrationStartsIn') }}
        </div>
      </div>
      <div class="text-center mt-2 mb-2" v-else-if="isRegistration">
        <div class="timer-title">
          {{ t('tournamentTab.tournaments.registrationEndsIn') }}
        </div>
      </div>
      <div class="text-center mt-2 mb-2" v-else-if="isInProgress">
        <div class="timer-title">
          {{ t('tournamentTab.tournaments.tournamentEndsIn') }}
        </div>
      </div>
    </template>

    <CardWithBgGradient v-if="!isDayTournament" class="timer-box" style="min-height: 68px">
      <EventTimer
        :end-date="currentEndDate"
        :index="round.id"
        :size="60"
        hide-letters
        hide-days
        hide-hours
        dividerSymbol=" : "
        :color-class="isRegistration ? 'gradient-text_red' : 'gradient-text'"
        @finished="renewRounds"
      />
    </CardWithBgGradient>

    <div class="mt-7">
      <CategoryGroupWidgetWithTimer
        v-for="(group, ind) in tournamentCurrencyRoundsGroups"
        :key="ind"
        :rounds="group"
        :hide-timer="!isDayTournament"
        @select-round="selectTournament($event)"
      />
    </div>
  </div>
</template>

<style scoped>
.timer-title {
  font-size: 0.8rem;
}
.back-icon {
  color: #5c7b3d;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.timer-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
