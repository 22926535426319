<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import NewRewardShowScreen from '../common/NewRewardShowScreen.vue'
import IconStar from '../icons/IconStar.vue'

const props = withDefaults(defineProps<{ goal: number; starsCashback: number }>(), {
  goal: 1000
})

const lineRef = ref<any>(null)
const showRewardModal = ref(false)

const { t } = useI18n()

const currentValue = computed(() => {
  return Math.min(((props.starsCashback || 0) / (props.goal || 1)) * 100, 100)
})

const showTicketReward = () => {
  if (props.starsCashback < props.goal) return

  showRewardModal.value = true

  // collectTicketReward()
}

const closeRewardModal = () => {
  showRewardModal.value = false
}
</script>

<template>
  <div class="">
    <div class="ma-auto" style="max-width: 90%">
      <div class="line" ref="lineRef">
        <div class="current-line" :style="{ width: `${currentValue}%` }"></div>
        <div class="current" :style="{ left: `${currentValue}%` }">
          <IconStar style="color: #e1dc64; width: 24px; height: 24px" />
        </div>
        <img class="ticket-img" src="/img/games/ticket.png" @click="showTicketReward" />
        <div v-if="starsCashback >= goal" class="glow"></div>
      </div>
      <div class="text">Free ticket</div>
    </div>

    <NewRewardShowScreen
      v-if="showRewardModal"
      @close="closeRewardModal"
      image="/img/games/ticket.png"
    >
      <template #text>
        <div class="award-text">
          {{ t('catchTheBullGame.yuoReceive', 1) }}
        </div>
      </template>
    </NewRewardShowScreen>
  </div>
</template>

<style scoped>
.line {
  width: 100%;
  height: 14px;
  background: #232319;
  border-radius: 30px;
  border: none;
  position: relative;
  background: #38381d;
}
.current-line,
.current,
.ticket-img {
  position: absolute;
}
.current-line {
  height: 14px;
  border-radius: inherit;
  border: none;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.71) 0%, rgba(247, 242, 112, 1) 100%);
}
.current {
  top: 50%;
  width: 48px;
  height: 48px;
  background: #656334;
  z-index: 2;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ticket-img {
  right: 0;
  top: 50%;
  z-index: 5;
  transform: translate(40%, -50%);
  width: 54px;
  height: 54px;
  max-width: 54px;
}
.glow {
  position: absolute;
  content: '';
  right: 0;
  top: 50%;
  z-index: 4;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  transform: translate(50%, -50%);
  background: transparent;
  box-shadow: 0px 0px 45px 18px var(--yellow);
}
.text {
  margin-top: 22px;
  color: #797c6f;
  text-align: end;
  position: relative;
  left: 24px;
}
</style>
