<script setup lang="ts">
import { storeUserWallet } from '@/application/services/useApi'
import { useTonConnectUi } from '@/application/services/useTonConnectUi'
import { useAuthStore } from '@/stores/auth'
import { notify } from '@/stores/message'
import { useTonConnect } from '@/stores/tonConnect'
import { useUserTasksStore, type Task } from '@/stores/userTasks'
import type { ConnectedWallet } from '@tonconnect/ui'
import WebApp from '@twa-dev/sdk'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ActionBtn from './common/ActionBtn.vue'
import Container from './common/Container.vue'
import DialogModal from './common/DialogModal.vue'
import IconStarBg from './common/IconStarBg.vue'
import ListItem from './common/ListItem.vue'
import NewRewardShowScreen from './common/NewRewardShowScreen.vue'
import DailyTaskSection from './DailyTaskSection.vue'
import IconBalance from './icons/IconBalance.vue'
import IconBolt from './icons/IconBolt.vue'
import IconBoltRounded from './icons/IconBoltRounded.vue'
import IconCalendar from './icons/IconCalendar.vue'
import IconCheck from './icons/IconCheck.vue'
import IconMore from './icons/IconMore.vue'
import IconX from './icons/IconX.vue'
import CheckJoinTaskButton from './earns/CheckJoinTaskButton.vue'

const { t } = useI18n()

const CONNECT_WALLET_ID = 6
const MAKE_TON_TRANSACTION_ID = 8

const userTasks = useUserTasksStore()
const { tasks, joinChannelTasks } = storeToRefs(userTasks)
const { getTasks, completeTask, fetchJoinChannelTasks } = userTasks
const auth = useAuthStore()
const modalOpen = ref(false)
const showRewardModal = ref(false)
const avatarAttributeReward = ref<string | null>(null)
const currentTask = ref<Task | null>(null)
const buttonLoading = ref(false)

const {
  modalOpen: tonWalletsModalOpen,
  registerOnStatusChange,
  tonConnectUiInit,
  transformAddressFromRawToFriendly,
  checkIsUserWalletConnected,
  disconnectWallet,
  isUserWalletConnected,
  onTransactionSigned
} = useTonConnectUi()

const { tonConnectUI } = storeToRefs(useTonConnect())

const isDailyTask = computed(
  () => !!currentTask.value?.user_task_sequence && currentTask.value.user_task_sequence.length > 0
)

onMounted(async () => {
  tonConnectUiInit().then(() => {
    console.log('TonConnectUi initialized')

    checkIsUserWalletConnected().then((result) => {
      if (result) {
        console.log('Wallet connected')
      } else {
        console.log('Wallet not connected')
      }
    })
  })

  if (tasks.value.length == 0) {
    getTasks().then(() => {
      console.log('Tasks loaded')
    })
  }

  fetchJoinChannelTasks()
})

const showTaskInfo = (task: Task) => {
  if (task.completed && task.id !== CONNECT_WALLET_ID) return
  modalOpen.value = true
  currentTask.value = task
}
const closeModal = () => {
  modalOpen.value = false
  currentTask.value = null
}
const completeTaskHandler = async (task: Task, isCompleted: boolean = false) => {
  if (buttonLoading.value) return

  switch (task.id) {
    case CONNECT_WALLET_ID:
      if (isUserWalletConnected.value) {
        await disconnectWallet()
      } else {
        await connectWallet(task)
      }
      break
    case MAKE_TON_TRANSACTION_ID:
      await makeTonTransaction()
      break
    default: {
      if (!!task.url && !isCompleted) {
        if (task.url.startsWith('https://t.me/')) {
          WebApp.openTelegramLink(task.url)
        } else {
          WebApp.openLink(task.url)
        }
      }
      buttonLoading.value = true
      const result = await completeTask(task.id)
      buttonLoading.value = false

      if ((!isDailyTask.value || !avatarAttributeReward.value) && result.success) {
        notify(
          t('earnTab.notifications.taskCompletedSuccessfully', { reward: task.reward }),
          'success'
        )
      }

      if (task.url && isCompleted && result.success) {
        if (result.success) {
          notify(t('earnTab.notifications.joinTaskCheckedSuccessfully'), 'success')
        } else {
          notify(t(result.message), 'error')
        }
      }

      if (isDailyTask.value) {
        if (avatarAttributeReward.value) showRewardModal.value = true
        await auth.getUser()
      }
      closeModal()
    }
  }
}

async function connectWallet(task: Task) {
  tonWalletsModalOpen()
  registerOnStatusChange(async (status: ConnectedWallet | null) => {
    if (status) {
      const wallet = transformAddressFromRawToFriendly(status.account.address)
      await storeUserWallet(wallet)
      console.log('wallet connected')

      if (!task.completed) {
        const { message, success } = await completeTask(CONNECT_WALLET_ID)

        if (message) {
          notify(message, success ? 'success' : 'error')
        }
      } else {
        notify('earnTab.tasks.connectWallet.messages.success', 'success')
      }

      await checkIsUserWalletConnected()
      closeModal()
    } else {
      isUserWalletConnected.value = false
      console.log('wallet disconnected')
      closeModal()
    }
  })
}

async function openTgChat(task: Task) {
  if (!task.url) return

  WebApp.openTelegramLink(task.url)

  if (task.task_check_available_at !== null) return

  await completeTask(task.id)
  await getTasks()
  currentTask.value = tasks.value.find((t) => t.id === task.id) || null
}

async function makeTonTransaction() {
  buttonLoading.value = true
  await tonConnectUiInit()

  onTransactionSigned(async (transaction) => {
    console.log('Transaction signed', transaction)
    await completeTask(MAKE_TON_TRANSACTION_ID)

    if (tonConnectUI.value) {
      tonConnectUI.value.closeModal()
    }

    closeModal()
  })

  if (!tonConnectUI.value) {
    console.error('TonConnectUI is not initialized')
    buttonLoading.value = false
    return
  }

  if (!isUserWalletConnected.value) {
    notify('Please connect your wallet first', 'error')
    buttonLoading.value = false
    closeModal()
    return
  }

  if (!(await tonConnectUI.value.connectionRestored)) {
    console.error('Connection is not restored')
    buttonLoading.value = false
    return
  }

  const transaction = {
    validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
    messages: [
      {
        address: 'UQDGsNfgUj_IvD7uzAkm8aQgnxbH61TsD10_i2K-S125XDW5',
        amount: '100000000'
        // stateInit: "base64bocblahblahblah==" // just for instance. Replace with your transaction initState or remove
      }
    ]
  }

  try {
    // const result =
    await tonConnectUI.value.sendTransaction(transaction, {
      modals: ['before', 'success', 'error'],
      notifications: ['before', 'success', 'error']
    })
    // you can use signed boc to find the transaction
    // const someTxData = await myAppExplorerService.getTransaction(result.boc)
    // alert('Transaction was sent successfully', someTxData)
  } catch (e) {
    console.error(e)
  } finally {
    buttonLoading.value = false
  }
}

const tasksModified = computed<Task[]>(() => {
  return tasks.value?.map((task) => {
    if (task.id === CONNECT_WALLET_ID && task.completed) {
      if (isUserWalletConnected.value) {
        return { ...task, button_title: 'earnTab.tasks.connectWallet.disconnectButtonTitle' }
      } else {
        return { ...task, button_title: 'earnTab.tasks.connectWallet.connectButtonTitle' }
      }
    }
    return task
  })
})
const closeRewardModal = () => {
  showRewardModal.value = false
  avatarAttributeReward.value = null
}
</script>

<template>
  <Container>
    <div style="max-width: 85%" class="ma-auto">
      <h1 class="page-title mt-15 mb-8" v-html="t('earnTab.completeTasksDaily')"></h1>
    </div>

    <div v-if="!!tasksModified && tasksModified.length > 0" class="list-container">
      <ListItem
        v-for="task in tasksModified"
        :key="task.id"
        :title="task.title"
        :price="task.reward"
        price-with-plus
        :disabled="!task.is_available"
        :completed="task.completed"
        @click.stop="showTaskInfo(task)"
      >
        <template #prepend>
          <IconBalance v-if="task.id === 1" style="width: 41px; height: 41px" />
          <img
            v-else-if="task.id === 2"
            src="/img/logos/gd_fund_logo.webp"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <IconX v-else-if="task.id === 3" style="width: 41px; height: 41px" />
          <img
            v-else-if="task.id === 15"
            src="/img/logos/green_gold_bot_logo.webp"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <img
            v-else-if="task.id === 16"
            src="/img/logos/test_the_million_logo.webp"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <img
            v-else-if="task.id === 17"
            src="/img/logos/daily_combo_logo.webp"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <img
            v-else-if="task.id === 18"
            src="/img/logos/piggy_bank_game_logo.webp"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <img
            v-else-if="task.id === 19"
            src="/img/logos/giraffe_combat_game_logo.webp"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <img
            v-else-if="task.id === 20"
            src="/img/logos/swopin_game_logo.webp"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <img
            v-else-if="task.id === 21"
            src="/img/logos/clockie_chaos_logo.webp"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <img
            v-else-if="task.id === 22"
            src="/img/logos/lions_game_logo.png"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <img
            v-else-if="task.id === 23"
            src="/img/logos/bull_market_game_logo.webp"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <img
            v-else-if="task.id === 24"
            src="/img/logos/cryptoapp_logo.webp"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <img
            v-else-if="task.id === 25"
            src="/img/logos/mng_lab_game_logo.webp"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <img
            v-else-if="task.id === 26"
            src="/img/logos/join_ks_aw_channel_logo.webp"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <img
            v-else-if="task.id === 27"
            src="/img/logos/clarnium_game_logo.webp"
            style="width: 41px; height: 41px; border-radius: 50%"
          />
          <IconBoltRounded v-else style="width: 48px; height: 48px" />
        </template>
        <template #append>
          <IconCheck v-if="task.completed" style="width: 25px; height: 25px" />
          <IconMore v-else style="width: 21px; height: 21px; stroke-width: 2px" />
        </template>
      </ListItem>
    </div>

    <ListItem v-else> Don't have active tasks </ListItem>

    <DialogModal v-if="modalOpen && isDailyTask" @close="closeModal">
      <DailyTaskSection
        :currentTask="currentTask"
        @completeTask="() => currentTask && completeTaskHandler(currentTask)"
        @showReward="avatarAttributeReward = $event"
      />
    </DialogModal>
    <DialogModal
      v-else-if="modalOpen && currentTask"
      @close="closeModal"
      :title="t(currentTask.title)"
      :description="t(currentTask.description)"
    >
      <template #icon>
        <IconStarBg :size="150">
          <IconCalendar class="text-primary" :style="{ width: '64px', height: '64px' }" />
        </IconStarBg>
      </template>

      <div>
        <span v-if="currentTask.completed"> You have already completed this task </span>

        <template v-if="currentTask.url && joinChannelTasks.includes(currentTask.id)">
          <ActionBtn
            class="mt-4"
            :text="t(currentTask.button_title || 'common.join')"
            :loading="buttonLoading"
            @click="openTgChat(currentTask)"
          >
            <template #append-icon>
              <IconBolt style="width: 22px; height: 22px; max-width: 22px" />
            </template>
          </ActionBtn>

          <CheckJoinTaskButton
            v-if="currentTask.task_check_available_at !== null"
            :task="currentTask"
            :button-loading="buttonLoading"
            @checkTask="() => currentTask && completeTaskHandler(currentTask, true)"
          />
        </template>

        <template v-else>
          <ActionBtn
            class="mt-4"
            :text="t(currentTask.button_title || 'common.claimReward')"
            :loading="buttonLoading"
            @click="completeTaskHandler(currentTask)"
          >
            <template #append-icon>
              <IconBolt style="width: 22px; height: 22px; max-width: 22px" />
            </template>
          </ActionBtn>
        </template>
      </div>
    </DialogModal>

    <NewRewardShowScreen
      v-if="showRewardModal && avatarAttributeReward"
      @close="closeRewardModal"
      :image="avatarAttributeReward"
    />
  </Container>
</template>

<style scoped>
.modal-url {
  margin-top: 10px;
  padding: 12px 18px;
  background: var(--app-background-darkest);
  color: white;
  border-radius: 12px;
  display: block;
}
.modal-reward {
  font-size: 24px;
  font-weight: bold;
}
</style>
