<script setup lang="ts">
import { collectTicketReward } from '@/application/services/useApi'
import Container from '@/components/common/Container.vue'
import NewRewardShowScreen from '@/components/common/NewRewardShowScreen.vue'
import { useCatchBullStore } from '@/stores/catchBullGame'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{ playingGame: boolean }>()
const emits = defineEmits(['show-shop'])

const progressWidth = ref(120)
const showRewardModal = ref(false)

const { t } = useI18n()

const {
  ticketFragmentsTotalCount,
  maxTickets,
  bombsFreeBoostsCount,
  doublePointsBoostCount,
  triplePointsBoostCount,
  slowdownBoostsCount,
  isBombFreeMode,
  isMultiplyBoostMode,
  isTripleBoostMode,
  isSlowDownMode
} = storeToRefs(useCatchBullStore())

const progressStyles = computed(() => ({
  width: `${progressWidth.value * (ticketFragmentsTotalCount.value / maxTickets.value)}px`
}))

const showShop = () => {
  if (props.playingGame) return
  emits('show-shop')
}
const useBombsFreeBust = () => {
  if (props.playingGame || bombsFreeBoostsCount.value == 0 || isBombFreeMode.value) return

  bombsFreeBoostsCount.value -= 1
  isBombFreeMode.value = true
}
const useMultiplyBust = () => {
  if (
    props.playingGame ||
    doublePointsBoostCount.value == 0 ||
    isMultiplyBoostMode.value ||
    isTripleBoostMode.value
  )
    return

  doublePointsBoostCount.value -= 1
  isMultiplyBoostMode.value = true
}
const useTripleMultiplyBust = () => {
  if (
    props.playingGame ||
    triplePointsBoostCount.value == 0 ||
    isTripleBoostMode.value ||
    isMultiplyBoostMode.value
  )
    return

  triplePointsBoostCount.value -= 1
  isTripleBoostMode.value = true
}
const useSlowDownBust = () => {
  if (props.playingGame || slowdownBoostsCount.value == 0 || isSlowDownMode.value) return

  slowdownBoostsCount.value -= 1
  isSlowDownMode.value = true
}
const showTicketReward = () => {
  if (props.playingGame || ticketFragmentsTotalCount.value < maxTickets.value) return

  showRewardModal.value = true

  collectTicketReward()
}
const closeRewardModal = () => {
  const tickets = Math.floor(ticketFragmentsTotalCount.value / maxTickets.value)

  ticketFragmentsTotalCount.value -= tickets * maxTickets.value
  showRewardModal.value = false
}
</script>

<template>
  <Container class="">
    <!-- info  -->
    <div class="flex justify-between items-center">
      <div class="relative">
        <div class="flex items-center">
          <img
            src="/img/games/puzzle.webp"
            style="width: 24px; height: 24px; top: 2px; position: relative"
          />
          <div class="progress-text">{{ ticketFragmentsTotalCount }}/{{ maxTickets }}</div>
        </div>
        <div class="progress" :style="{ width: `${progressWidth}px` }">
          <div class="progress-inner-wrapper">
            <div class="progress-inner" :style="progressStyles"></div>
          </div>
        </div>
        <img class="ticket-img" src="/img/games/ticket.png" @click="showTicketReward" />
        <div v-if="ticketFragmentsTotalCount >= maxTickets" class="glow"></div>
      </div>

      <div class="shopImg">
        <img class="ofi" src="/img/games/store3.webp" @click="showShop" />
        <!-- <IconShop v-show="!playingGame" style="width: 68px; height: 46px" @click="showShop" /> -->
      </div>
    </div>

    <!-- buttons  -->
    <div class="mt-4 flex justify-between items-center">
      <button class="btn bombs-btn" :class="{ active: isBombFreeMode }" @click="useBombsFreeBust">
        <div class="btn-inner-wrapper">
          <div class="btn-inner">
            <div class="btn-title">
              {{ t('catchTheBullGame.bombs') }}
            </div>
            <!-- <IconNoBomb style="width: 30px; height: 30px" /> -->
            <img src="/img/games/navigation_bomb.webp" alt="" style="width: 44px; height: 44px" />
            <div class="btn-available">x{{ bombsFreeBoostsCount }}</div>
          </div>
        </div>
      </button>

      <button
        class="btn boost-btn"
        :class="{ active: isMultiplyBoostMode }"
        @click="useMultiplyBust"
      >
        <div class="btn-inner-wrapper">
          <div class="btn-inner">
            <div class="btn-title">{{ t('catchTheBullGame.boost') }} x2</div>
            <!-- <IconBolt style="width: 30px; height: 30px" class="text-yellow" /> -->
            <img src="/img/games/navigation_boost.webp" alt="" style="width: 44px; height: 44px" />
            <div class="btn-available">x{{ doublePointsBoostCount }}</div>
          </div>
        </div>
      </button>

      <button
        class="btn boost-btn"
        :class="{ active: isTripleBoostMode }"
        @click="useTripleMultiplyBust"
      >
        <div class="btn-inner-wrapper">
          <div class="btn-inner">
            <div class="btn-title">{{ t('catchTheBullGame.boost') }} x3</div>
            <!-- <IconBolt style="width: 30px; height: 30px" class="text-yellow" /> -->
            <img src="/img/games/navigation_boost3.webp" alt="" style="width: 44px; height: 44px" />
            <div class="btn-available">x{{ triplePointsBoostCount }}</div>
          </div>
        </div>
      </button>

      <button class="btn down-btn" :class="{ active: isSlowDownMode }" @click="useSlowDownBust">
        <div class="btn-inner-wrapper">
          <div class="btn-inner">
            <div class="btn-title">
              {{ t('catchTheBullGame.down') }}
            </div>
            <!-- <IconSnow style="width: 30px; height: 30px" class="text-yellow" /> -->
            <img src="/img/games/snow.webp" alt="" style="width: 44px; height: 44px" />
            <div class="btn-available">x{{ slowdownBoostsCount }}</div>
          </div>
        </div>
      </button>
    </div>

    <NewRewardShowScreen
      v-if="showRewardModal"
      @close="closeRewardModal"
      image="/img/games/ticket.png"
    >
      <template #text>
        <div class="award-text">
          {{ t('catchTheBullGame.yuoReceive', Math.floor(ticketFragmentsTotalCount / maxTickets)) }}
        </div>
      </template>
    </NewRewardShowScreen>
  </Container>
</template>

<style scoped>
.progress {
  width: 120px;
  height: 5px;
  background: linear-gradient(122.92deg, #feffaf 6.07%, #ced11d 82.15%);
  border-radius: 8px;
  padding: 0.5px;
  position: relative;
  overflow: hidden;
}
.progress-inner-wrapper {
  width: 100%;
  height: 100%;
  background: black;
}
.progress-inner {
  height: 100%;
  background: linear-gradient(#ced11d 82.15%, #feffaf 6.07%);
}
.progress,
.progress-inner-wrapper,
.progress-inner {
  border-radius: 8px;
}
.ticket-img {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 5;
  transform: translate(50%, -50%);
  width: 36px;
  height: 36px;
  max-width: 36px;
}
.glow {
  position: absolute;
  content: '';
  right: 0;
  top: 50%;
  z-index: 4;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  transform: translate(50%, -50%);
  background: transparent;
  box-shadow: 0px 0px 45px 18px var(--yellow);
}
.progress-text {
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.shopImg {
  width: 36px;
  height: 36px;
}
.btn {
  box-sizing: border-box;
  background-clip: padding-box;
  border: solid 1px transparent;
  position: relative;
  border-radius: 12px;
  padding: 0;
  background: black;
  min-width: 75px;
}
.bombs-btn:before,
.boost-btn:before,
.down-btn:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px; /* !importanté */
  border-radius: inherit; /* !importanté */
}
.bombs-btn:before {
  background: linear-gradient(90.3deg, #fe5258 -2.51%, #ffa9ac 100.21%);
}
.boost-btn:before {
  background: linear-gradient(90.3deg, #feffd5 -2.51%, #fdff6b 100.21%);
}
.down-btn:before {
  background: linear-gradient(90.3deg, #e1ddff -2.51%, #5f56fe 100.21%);
}
.bombs-btn .btn-inner-wrapper,
.boost-btn .btn-inner-wrapper,
.down-btn .btn-inner-wrapper {
  border-radius: inherit;
}
.bombs-btn .btn-inner-wrapper {
  background: rgba(254, 82, 88, 0.1);
}
.bombs-btn.active .btn-inner-wrapper {
  background: rgba(254, 82, 88, 0.4);
}
.boost-btn .btn-inner-wrapper {
  background: rgba(253, 255, 107, 0.1);
}
.boost-btn.active .btn-inner-wrapper {
  background: rgba(253, 255, 107, 0.4);
}
.down-btn .btn-inner-wrapper {
  background: rgba(95, 86, 254, 0.1);
}
.down-btn.active .btn-inner-wrapper {
  background: rgba(95, 86, 254, 0.4);
}
.btn-inner {
  display: flex;
  flex-direction: column;
  padding: 6px 10px;
  flex-wrap: nowrap;
  border-radius: inherit;
  justify-content: center;
  align-items: center;
}
.btn-inner div {
  font-family: Urbanist;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.btn-inner svg {
  margin-top: -2px;
  margin-bottom: -5px;
}
.btn-available {
  margin-top: -18px;
  align-self: flex-end;
  font-size: 7px !important;
  font-weight: bold !important;
}
.btn-title {
  font-size: 11px !important;
  font-weight: bold !important;
  margin-bottom: -5px;
}
.bombs-btn .btn-inner div {
  background: linear-gradient(90.3deg, #ffb8ba -2.51%, #ff666c 100.21%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.boost-btn .btn-inner div {
  background: linear-gradient(90.3deg, #feffc6 -2.51%, #fdff6b 100.21%);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.down-btn .btn-inner div {
  background: linear-gradient(90.3deg, #e9ddff -2.51%, #9486fe 100.21%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.award-text {
  font-size: 24px;
  font-weight: bold;
}
@media (min-width: 410px) {
  .btn-inner {
    padding: 6px 13px;
  }
}
</style>
