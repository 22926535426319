import { fetchRaffle } from '@/application/services/useApi'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export interface ITicket {
  id: number
  stars_cost: number
  quantity: number
  url: string
  special_cost?: number
}

export const useRaffleStore = defineStore('raffles', () => {
  const userTicketsCount = ref(0)
  const prizePool = ref(0)
  const raffleFinishDate = ref('')
  const availableTickets = ref<ITicket[]>([])
  const unusedTotalStarsCashback = ref(0)

  async function getCurrentRaffle() {
    const data = await fetchRaffle()
    userTicketsCount.value = data.userTicketsCount
    prizePool.value = data.prizePool
    raffleFinishDate.value = data.raffleFinishDate
    availableTickets.value = data.availableTickets
    unusedTotalStarsCashback.value = data.unusedTotalStarsCashback
  }

  return {
    userTicketsCount,
    prizePool,
    raffleFinishDate,
    availableTickets,
    getCurrentRaffle,
    unusedTotalStarsCashback
  }
})
