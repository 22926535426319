<script setup lang="ts">
import WebApp from '@twa-dev/sdk'
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'
import { RouterView } from 'vue-router'
import Alert from './components/common/AlertMessage.vue'
import LoadingWidget from './components/LoadingWidget.vue'
import router from './router'
import { useAuthStore } from './stores/auth'
import DesKtopView from './views/DesKtopView.vue'

const SCREENDELAY = 4000

const isMobile = ref(true)
const loading = ref(true)
const startX = ref(0)
const startY = ref(0)

const authStore = useAuthStore()

function setupUserEvents(user: App.DTO.User.UserData) {
  window.Echo.private(`App.Models.User.${user.id}`).listen('RankAchieved', () => {
    authStore.getUser()
  })
}

function subscribeToNotifications() {
  window.Echo.channel('notifications')
    .listen('MaintenanceModeEnabled', () => {
      router.push({ path: '/maintenance' }).then(() => {})
    })
    .listen('MaintenanceModeDisabled', () => {
      /**
       * Redirect to the home page with refresh
       * because we need to re-fetch all the data
       */
      window.location.href = '/'
    })
}

const preventHorizontalScroll = (event: any) => {
  // Allow swiping on elements with the "slider" class
  if (event.target.closest('.carousel') || event.target.closest("input[type='range']")) {
    return
  }

  // Detect horizontal swipe direction
  const touch = event.touches[0]
  const deltaX = Math.abs(touch.clientX - startX.value)
  const deltaY = Math.abs(touch.clientY - startY.value)

  if (deltaX > deltaY) {
    // Prevent horizontal scroll globally, except on sliders
    event.preventDefault()
  }
}

const handleTouchStart = (event: any) => {
  // Store the initial touch positions for comparison
  const touch = event.touches[0]
  startX.value = touch.clientX
  startY.value = touch.clientY
}

onMounted(() => {
  /**
   * Prevent scrolling on mobile
   */
  const overflow = 0
  document.body.style.overflowY = 'hidden'
  document.body.style.marginTop = `${overflow}px`
  document.body.style.height = window.innerHeight + overflow + 'px'
  document.body.style.paddingBottom = `${overflow}px`
  window.scrollTo(0, overflow)

  subscribeToNotifications()

  if (!authStore.user) {
    const languageCode = WebApp.initDataUnsafe?.user?.language_code

    authStore.loginUser(WebApp.initData, languageCode).then((user) => {
      if (user) {
        setupUserEvents(user)
      }
    })
  }

  window.addEventListener('touchmove', preventHorizontalScroll, { passive: false })
  window.addEventListener('touchstart', handleTouchStart)

  setTimeout(() => {
    loading.value = false
  }, SCREENDELAY)
})

onBeforeMount(() => {
  if (import.meta.env.DEV) {
    isMobile.value = true
  } else if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  ) {
    isMobile.value = true
  } else {
    isMobile.value = false
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('touchmove', preventHorizontalScroll)
  window.removeEventListener('touchstart', handleTouchStart)
})
</script>

<template>
  <div v-if="isMobile">
    <Alert />

    <RouterView />

    <LoadingWidget v-if="loading" :progress="SCREENDELAY" />
  </div>
  <DesKtopView v-else />
</template>

<style scoped></style>
