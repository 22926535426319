<script setup lang="ts">
import { subscribeToTicker, unSubscribeToTicker } from '@/application/services/tickersPrice'
import { useGlobalIntervalStore } from '@/stores/globalInterval'
import { useTournamentStore } from '@/stores/tournament'
import { fixedDigitsAfterPoint } from '@/utils/amountFormat'
import { storeToRefs } from 'pinia'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import BtnPrimary from '../common/BtnPrimary.vue'
import CardWithBgGradient from '../common/CardWithBgGradient.vue'
import Container from '../common/Container.vue'
import EventTimer from '../common/EventTimer.vue'
import IconBack from '../icons/IconBack.vue'
import IconDecrease from '../icons/IconDecrease.vue'
import IconIncrease from '../icons/IconIncrease.vue'

interface Props {
  currencyToPair: string
}

const props = defineProps<Props>()
defineEmits(['registered', 'close'])

const btcPrice = ref(0)
const now = ref(new Date())
const initialHeight = ref(window.innerHeight)
const keyboardVisible = ref(false)
const bidInput = ref<any>(null)
// const chosenVariant = ref<'up' | 'down' | null>(null)

const { t } = useI18n()

const tournamentStore = useTournamentStore()
const { registerUserPredictionForRound, setRegistrationStatusForRound } = tournamentStore
const { selectedCurrencyRound: round } = storeToRefs(tournamentStore)

const bidValue = ref<any>(round.value?.user_bid)

const { tick, showMenuBar } = storeToRefs(useGlobalIntervalStore())

const tickerPair = computed(() => (round.value?.ticker || '') + props.currencyToPair)

const isPreRegistration = computed(
  () => round.value && now.value < round.value.registration_starts_at
)
const isRegistration = computed(
  () =>
    round.value &&
    round.value.registration_starts_at <= now.value &&
    now.value < round.value.started_at
)
const isInProgress = computed(
  () => round.value && round.value.started_at <= now.value && now.value < round.value.ended_at
)
const descriptionText = computed(() => {
  if (isInProgress.value) return 'tournamentEndsIn'
  if (isRegistration.value) return 'registrationEndsIn'
  return 'registrationStartsIn'
})
const tournamentTitle = computed(() => {
  return t('tournamentTab.tournaments.bet.roundTitle', {
    ticker: round.value?.ticker,
    title: roundCategoryTitle.value,
    message: t(`tournamentTab.tournaments.${descriptionText.value}`)
  })
})
const priceFromText = computed(() => {
  return t('tournamentTab.tournaments.bet.priceFromBinance')
})

const currentEndDate = computed(() => {
  if (!round.value) return undefined
  if (isRegistration.value) return round.value.started_at
  if (isInProgress.value) return round.value.ended_at
  return round.value.registration_starts_at
})

const roundCategoryTitle = computed(() => t((round.value as any).categoryTitle))
const isDayTournament = computed(() => (round.value as any)?.categoryId == 4)
const canPlay = computed(
  () => isRegistration.value && !round.value?.is_registered
)

watch(tick, () => {
  now.value = new Date()
})

const updateTicker = ({ price }: { price: string }) => {
  btcPrice.value = Number(price)
}

const checkKeyboard = () => {
  const currentHeight = window.innerHeight
  keyboardVisible.value = currentHeight < initialHeight.value
  showMenuBar.value = !keyboardVisible.value
}
const focusHandler = () => {
  bidValue.value = btcPrice.value
}

onMounted(() => {
  subscribeToTicker(tickerPair.value, updateTicker)
  if (isDayTournament.value) {
    window.addEventListener('resize', checkKeyboard)
    bidInput.value.addEventListener('focus', focusHandler)
  }
})
onBeforeUnmount(() => {
  unSubscribeToTicker(tickerPair.value)
  showMenuBar.value = true
  window.removeEventListener('resize', checkKeyboard)
})

const makeBid = (bit: 'up' | 'down') => {
  if (!canPlay.value) return
  if (round.value) {
    registerUserPredictionForRound(round.value.id, bit).then(() => {
      if (round.value) setRegistrationStatusForRound(round.value.id, true, bit)
      // emit('registered')
    })
  }
}

const makeCurrencyValueBid = () => {
  if (!canPlay.value || !bidValue.value) return
  if (round.value) {
    registerUserPredictionForRound(round.value.id, bidValue.value).then(() => {
      if (round.value) setRegistrationStatusForRound(round.value.id, true, bidValue.value as any)
      //   emit('registered')
    })
  }
}
</script>

<template>
  <div v-if="round" class="round-body">
    <div class="flex justify-center flex-col items-center gap-2 bet-title-block">
      <h2 class="page-title" v-html="t(round.title)"></h2>

      <div v-if="!keyboardVisible" class="ma-auto" style="max-width: 58%">
        <h3 class="page-sub-title mt-4" v-html="tournamentTitle"></h3>
      </div>
      <IconBack class="back-icon" @click="$emit('close')" />
    </div>

    <CardWithBgGradient class="mt-7" style="min-height: 60px">
      <div class="round-timer">
        <EventTimer
          :end-date="currentEndDate"
          :size="50"
          hide-days
          :hide-hours="!isDayTournament"
          hide-letters
          divider-symbol=":"
        />
      </div>
    </CardWithBgGradient>

    <div class="mt-7 round-price">
      <div class="flex justify-center">
        <div class="price-circle">
          <div class="price-inner flex flex-col items-center justify-center">
            <div class="ticker-name">
              {{ t('tournamentTab.tournaments.bet.tickerPrice', { ticker: round.ticker }) }}
            </div>
            <div class="ticker-value mt-2 mb-2">${{ fixedDigitsAfterPoint(btcPrice) }}</div>
            <div class="ticker-text" v-html="priceFromText"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="round-actions" :class="{ keyboard: keyboardVisible }">
      <Container v-if="!isPreRegistration">
        <template v-if="!isDayTournament">
          <div
            class="mt-10 info-text"
            v-html="
              t('tournamentTab.tournaments.bet.guessPrice', {
                ticker: round.ticker
              })
            "
          ></div>

          <div
            class="mt-5 flex justify-center gap-4 items-center btn"
            :class="{ blurred: !canPlay }"
          >
            <button class="bet-btn ofi-btn" @click="makeBid('down')">
              <!-- :class="{ highlighted: waitingResultsBet == 'down' }" -->
              <img
                :src="
                  round.user_bid == 'down'
                    ? `/img/bids/doom_active_ro.webp`
                    : `/img/bids/doom_ro.webp`
                "
                class="ofi"
              />
              <div class="bet-btn-text" :class="{ active: round.user_bid == 'down' }">
                {{ t('luckyTrade.down') }}
                <IconDecrease style="width: 24px; height: 24px" />
              </div>
            </button>

            <button class="bet-btn ofi-btn" @click="makeBid('up')">
              <!-- :class="{ highlighted: waitingResultsBet == 'up' }" -->
              <img
                :src="
                  round.user_bid == 'up'
                    ? `/img/bids/moon_active_ro.webp`
                    : `/img/bids/moon_ro.webp`
                "
                class="ofi"
              />

              <div class="bet-btn-text" :class="{ active: round.user_bid == 'up' }">
                {{ t('luckyTrade.up') }}
                <IconIncrease style="width: 24px; height: 24px" />
              </div>
            </button>
          </div>
        </template>

        <!-- manual input in day tournament  -->
        <div v-else class="mt-10">
          <div class="mt-4 bid-actions">
            <div class="bid-input-bg">
              <input
                ref="bidInput"
                type="number"
                :model-value="canPlay ? null : round.user_bid"
                v-model="bidValue"
                class="bid-input"
                :placeholder="t('tournamentTab.tournaments.bet.value')"
                :disabled="!canPlay"
              />
            </div>
            <BtnPrimary
              class="bid-btn"
              :class="{ blurred: !canPlay || !bidValue }"
              big
              block
              @click="makeCurrencyValueBid"
            >
              {{ t('tournamentTab.tournaments.bet.bid') }}
            </BtnPrimary>
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>

<style scoped>
.round-body {
  height: calc(100vh - 142px);
  display: flex;
  flex-direction: column;
}
.round-actions {
  /* margin-bottom: 30px; */
  /* margin-top: auto; */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 110px;
}
.round-actions.keyboard {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
}
.ticker-name {
  text-transform: capitalize;
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ticker-value {
  color: var(--white, #f3ffe9);
  font-family: Courier, monospace;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}
.ticker-text {
  color: #fff;
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 auto;
  max-width: 55%;
  text-align: center;
}
.info-text {
  color: var(--white, #f3ffe9);
  text-align: center;
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.bet-btn {
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 200px;
  transition: all 0.3s linear;
}
.bet-btn-text {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-60%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--white);
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 18px 16px;
  text-transform: uppercase;
}
.bet-btn-text.active {
  transform: translateY(-53%);
}
/* .betBtn {
  padding: 4px 12px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
} */
/* .betBtn.increase,
.betBtn.decrease {
  color: var(--black, #0d0e0c);
  font-family: Urbanist;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  max-width: 200px;
  padding: 18px 40px;
  text-transform: capitalize;
} */
/* .betBtn.increase {
  border-radius: 12px;
  background: linear-gradient(123deg, #d1ffa6 6.07%, #71be2b 82.15%);

  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.2) inset,
    0px 2px 4px 0px rgba(0, 0, 0, 0.12);
} */
/* .betBtn.decrease {
  border-radius: 12px;
  background: linear-gradient(123deg, #ffc1c3 6.07%, #fe5258 82.15%);

  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.2) inset,
    0px 2px 4px 0px rgba(0, 0, 0, 0.12);
} */
.bet-title-block {
  position: relative;
}
.back-icon {
  color: #5c7b3d;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.round-timer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.price-circle {
  width: 190px;
  height: 190px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  background: url('/img/icons/green_coin_1.webp');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}
.price-inner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.btn.blurred,
.bid-btn.blurred {
  opacity: 0.6;
}
.betBtn.highlighted {
  box-shadow: 0 0px 18px 10px var(--primary);
}
.bid-actions {
  display: grid;
  grid-template-columns: 1fr 120px;
  gap: 8px;
}
.bid-btn,
.bid-input {
  height: 56px;
}
.bid-input-bg {
  background: #314222;
  border-radius: 12px;
}
.bid-input {
  width: 100%;
  color: var(--white);
  padding: 0 16px;
  font-size: 16px;
  border: none;
  border-radius: 12px;
  font-weight: 500;
  background: radial-gradient(
    100.27% 103.53% at -1.76% 81.25%,
    rgba(150, 207, 96, 0.38) 0%,
    #314222 98.5%
  );
}
.bid-input:focus {
  outline: none;
}
.bid-input::placeholder {
  font-family: Urbanist;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
@media (min-width: 470px) {
  .round-actions {
    margin-bottom: 120px;
  }
}
</style>
