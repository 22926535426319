import type { CatchBullGame } from './catchTheBull'

export class InputHandler {
  game: CatchBullGame

  constructor(game: CatchBullGame) {
    this.game = game

    window.addEventListener('touchstart', (e) => {
      const canvas = document.getElementById('canvas')
      // console.log('canvas?.offsetTop: ', canvas?.offsetTop)

      this.game.touchCoord = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY - (canvas?.offsetTop || 0) - 54
      }
    })
    // window.addEventListener('mousedown', (e) => {
    //   console.log('mousedown: ', e)
    // })
    window.addEventListener('touchend', () => {
      this.game.touchCoord = null
    })
  }
}
