import type { CatchBullGame } from '../catchTheBull.js'
import { BaseBull } from './bull.js'

export class BullBomb extends BaseBull {
  constructor(game: CatchBullGame, x: number, y: number, col: number, lifeTime: number) {
    super(game, x, y, col, lifeTime)
    this.isBomb = true
    this.sWidth = 340
    this.sHeight = 466
    this.img = document.getElementById('bomb')
  }
}
