<script setup lang="ts">
import { useCatchBullStore } from '@/stores/catchBullGame'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { NEXT_LEVEL_STRIKE } from './consts'

const { strike } = storeToRefs(useCatchBullStore())

const progressStyles = computed(() => ({
  width: `${Math.min((strike.value * 100) / (2 * NEXT_LEVEL_STRIKE), 100)}%`
}))

const activeIndicator = computed(() => {
  if (strike.value < NEXT_LEVEL_STRIKE) return 1
  if (strike.value >= NEXT_LEVEL_STRIKE && strike.value < 2 * NEXT_LEVEL_STRIKE) return 2
  return 3
})
</script>

<template>
  <div class="progress">
    <div class="progress-inner" :style="progressStyles"></div>
    <div class="points point1" :class="{ active: activeIndicator == 1 }">
      <div>
        <img src="/img/games/bolt.png" style="width: 11px; height: 16px" />
      </div>
    </div>
    <div class="points point2 dots" :class="{ active: activeIndicator == 2 }">
      <div class="dots-text">x2</div>
    </div>
    <div class="points point3 dots" :class="{ active: activeIndicator == 3 }">
      <div class="dots-text">x3</div>
    </div>
  </div>
</template>

<style scoped>
.dots {
  width: 29px;
  height: 29px;
  border-radius: 100%;
  background: linear-gradient(90.3deg, #edffdd -2.51%, #b2ff6b 100.21%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.dots .dots-text {
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.005em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  background: radial-gradient(rgba(48, 91, 8, 0.37) 0%, #314222 98.5%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.points {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.point1,
.point1 div {
  border-radius: 100%;
}
.point1 {
  width: 29px;
  height: 29px;
  left: 0;
  z-index: 1;
  width: 29px;
  height: 29px;
}
.point1.active {
  width: 40px;
  height: 40px;
  background: linear-gradient(90.3deg, #edffdd -2.51%, #b2ff6b 100.21%);
  padding: 5.5px;
}
.point1 div {
  width: 29px;
  height: 29px;
  background: #456029;
}
.point1 div img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.point3 {
  left: 100%;
}
.point2 {
  left: 50%;
}

.progress {
  width: 100%;
  height: 20px;
  box-sizing: border-box;
  background-clip: padding-box;
  border: solid 1px transparent;
  position: relative;
  border-radius: 14px;
  border: 2px solid rgba(116, 156, 80, 0.54);
  padding: 0;
  background: transparent;
}
.progress-inner {
  height: 100%;
  background: linear-gradient(89.9deg, #c1c300 0.08%, #feffc8 121.2%);
  border-radius: inherit;
  transition: all 0.3s linear;
}
.points.active {
  width: 40px;
  height: 40px;
  background: linear-gradient(90.3deg, #edffdd -2.51%, #b2ff6b 100.21%);
  transition: all 0.3s linear;
}
</style>
