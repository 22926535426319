<script setup lang="ts">
import type { Task } from '@/stores/userTasks'
import ActionBtn from '../common/ActionBtn.vue'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { task: currentTask } = defineProps<{
  task: Task
  buttonLoading: boolean
}>()

const emit = defineEmits(['checkTask'])

const checkButtonDisabled = computed(() => {
  if (!currentTask?.task_check_available_at) return true

  if (countDownSecondsLeft.value <= 0) return false

  return true
})

const countDownSecondsLeft = ref(0)
const checkButtonText = computed(() => {
  if (countDownSecondsLeft.value <= 0) {
    return t('common.check')
  }

  const minutes = Math.floor(countDownSecondsLeft.value / 60000)
  const seconds = Math.floor((countDownSecondsLeft.value % 60000) / 1000)

  return t('earnTab.notifications.joinTaskValidatingTimer', {
    time: `${minutes}:${seconds.toString().padStart(2, '0')}`
  })
})

let countDownInterval: NodeJS.Timeout | null = null

const emitCheckTask = () => {
  if (checkButtonDisabled.value) return

  emit('checkTask')
}

onMounted(() => {
  if (currentTask.task_check_available_at) {
    const now = new Date().getTime()
    const availableAt = new Date(currentTask.task_check_available_at).getTime()
    const diff = availableAt - now

    if (diff > 0) {
      countDownSecondsLeft.value = diff
      countDownInterval = setInterval(() => {
        countDownSecondsLeft.value -= 1000
        if (countDownSecondsLeft.value <= 0) {
          clearInterval(countDownInterval!)
        }
      }, 1000)
    }
  }
})
</script>

<template>
  <ActionBtn
    class="mt-4"
    :text="checkButtonText"
    :loading="buttonLoading"
    :disabled="checkButtonDisabled"
    @click="emitCheckTask"
  >
  </ActionBtn>
</template>
