<script setup lang="ts">
import { getUserDailyCombo, type ApiResponse } from '@/application/services/useApi'
import { notify } from '@/stores/message'
import { useUserTasksStore } from '@/stores/userTasks'
import { comboBulls } from '@/utils/comboBulls'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import BtnPrimary from '../common/BtnPrimary.vue'
import CardWithBgGradient from '../common/CardWithBgGradient.vue'
import Container from '../common/Container.vue'
import DialogModal from '../common/DialogModal.vue'
import IconBack from '../icons/IconBack.vue'

const emit = defineEmits(['close', 'played'])

const { t } = useI18n()

const tasksStore = useUserTasksStore()
const { isTaskIsCompletedById } = storeToRefs(tasksStore)

const selectedIcons = ref<any[]>([null, null, null, null])
const showDialog = ref(false)
// const showTimerToNextGame = ref<Date | null>(null)
const icons = ref<any[]>([])

// const gameOver = computed(() => isTaskIsCompletedById.value(14))
const gameOver = ref(false)
const valid = computed(
  () => selectedIcons.value.length === 4 && selectedIcons.value.every((el) => !!el)
)

const selectIcon = (icon: any) => {
  if (gameOver.value || isSelected(icon.id) || valid.value) return
  const emptyIndex = selectedIcons.value.findIndex((el) => !el)

  if (emptyIndex < 4) {
    selectedIcons.value[emptyIndex] = icon
  }
}

const unSelectIcon = (index: number) => {
  if (gameOver.value) return
  selectedIcons.value.splice(index, 1, null)
}

const isSelected = (id: number) => {
  return selectedIcons.value.some((el) => el?.id == id)
}
const isWrongIcon = (ind: number) => {
  return selectedIcons.value[ind]?.incorrect
}
const checkCombination = async () => {
  if (!valid.value || gameOver.value) {
    return
  }

  const ids = selectedIcons.value.map((el) => el.id)

  await tasksStore
    .completeTask(14, ids)
    .then((response: ApiResponse<any>) => {
      if (response.success) {
        notify(t('puzzleGame.userComboSuccess'))
      }
    })
    .catch((error) => {
      console.error(error)
    })

  emit('played')

  const res = await getUserDailyCombo().catch(() => {})
  // const res: any = {
  //   '1': true,
  //   '3': true,
  //   '4': false,
  //   '6': false
  // }

  gameOver.value = true

  if (res) {
    selectedIcons.value.forEach((icon) => {
      if (icon) {
        if (Object.keys(res).includes('' + icon.id)) {
          icon.incorrect = !res[icon.id]
        } else {
          icon.incorrect = true
        }
      }
    })
  }
}

onMounted(async () => {
  icons.value = [...comboBulls]

  gameOver.value = isTaskIsCompletedById.value(14)
})
</script>

<template>
  <Container class="puzzle overflowOn">
    <div class="body">
      <div class="relative mt-7">
        <div class="page-title">
          {{ t('puzzleGame.puzzle') }}
        </div>
        <div class="page-sub-title text ma-auto">{{ t('puzzleGame.findCorrectCombination') }}</div>
        <IconBack
          class="back-icon text-light-green"
          style="width: 20px; height: 20px"
          @click="$emit('close')"
        />
      </div>

      <CardWithBgGradient class="answer-bar mt-7">
        <div
          v-for="n in 4"
          :key="n"
          class="answer-bar-item"
          :class="{ wrong: isWrongIcon(n - 1) }"
          @click="unSelectIcon(n - 1)"
        >
          <!-- :style="{ background: selectedIcons[n - 1] && selectedIcons[n - 1].color }" -->
          <img :src="'/img/combo/combo_bg.webp'" class="variants-bar-item-bg ofi" />
          <img
            v-if="selectedIcons[n - 1] && !selectedIcons[n - 1].incorrect"
            :src="selectedIcons[n - 1].url"
            class="variants-bar-item-icon ofi"
          />
        </div>
      </CardWithBgGradient>

      <CardWithBgGradient class="variants-bar mt-4">
        <div
          v-for="icon in icons"
          :key="icon.id"
          class="variants-bar-item"
          :class="{ disabled: isSelected(icon.id) }"
          @click="selectIcon(icon)"
        >
          <!-- :style="{ background: icon.color }" -->
          <img :src="'/img/combo/combo_bg.webp'" class="variants-bar-item-bg ofi" />
          <img :src="icon.url" class="variants-bar-item-icon ofi" />
        </div>
      </CardWithBgGradient>
    </div>

    <div class="action-bar mt-10">
      <BtnPrimary block big :disabled="!valid || gameOver" @click="checkCombination">
        {{ t('puzzleGame.check') }}
      </BtnPrimary>
    </div>

    <DialogModal
      v-if="showDialog"
      @close="showDialog = false"
      :title="t('puzzleGame.userComboFailed')"
      :description="t('puzzleGame.notLucky')"
    >
      <BtnPrimary block big class="mt-12" @click="showDialog = false"> Ok </BtnPrimary>
    </DialogModal>
  </Container>
</template>

<style scoped>
.puzzle {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.body {
  flex-grow: 1;
}
.answer-bar {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.answer-bar-item,
.variants-bar-item {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 22px;
  /* border: 1px solid rgba(177, 247, 112, 0.46); */
  background: transparent;
  /* background-image: url();
  background-position: center;
  background-size: contain; */
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  /* padding: 2px; */
  position: relative;
}
/* .variants-bar-item-bg {
} */
.variants-bar-item-icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  max-height: 100%;
  padding: 7px;
}
.answer-bar-item.wrong {
  position: relative;
}
.answer-bar-item.wrong::after,
.answer-bar-item.wrong::before {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  background: var(--red);
  top: 50%;
  left: 50%;
  transform-origin: center;
}
.answer-bar-item.wrong::after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.answer-bar-item.wrong::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.variants-bar {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
}
.variants-bar-item.disabled {
  opacity: 0.2;
}
.action-bar {
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0;*/
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 32px;
}
.text {
  max-width: 75%;
  align-self: center;
}
.next-game-text {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}
</style>
