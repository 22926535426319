import { type CatchBullGame } from '../catchTheBull'
import { GAME_ELEMENT_WIDTH } from '../consts'

export class BaseBull {
  game: CatchBullGame
  x: number
  y: number
  row: number
  col: number
  width: number
  height: number
  sWidth: number
  sHeight: number
  img: any
  needToRemove: boolean
  fps: number
  timer: number
  interval: number
  checkingCollision: boolean
  isBomb: boolean

  constructor(game: CatchBullGame, x: number, row: number, col: number, lifeTime: number) {
    this.game = game
    this.x = x
    this.row = row
    this.col = col
    this.width = GAME_ELEMENT_WIDTH
    this.height = this.width
    this.sWidth = 1080
    this.sHeight = 1500
    this.y = this.row * this.height //- 10 * this.row

    this.img = document.getElementById('bull-1')
    this.needToRemove = false

    this.fps = 1
    this.timer = 0
    this.interval = lifeTime / this.fps
    this.checkingCollision = false
    this.isBomb = false
  }
  update(deltaTime: number) {
    if (this.timer > this.interval) {
      // this.frameX++;
      this.timer = 0
      this.needToRemove = true
      this.game.gates[this.row][this.col].isOpen = false
    } else {
      this.timer += deltaTime
    }
  }
  draw(ctx: CanvasRenderingContext2D) {
    ctx.drawImage(
      this.img,
      0,
      0,
      this.sWidth,
      this.sHeight,
      this.x,
      this.y,
      this.width,
      this.height
    )
  }
}
