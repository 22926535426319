<script setup lang="ts">
import { useAuthStore, type User } from '@/stores/auth'
import { useUserEnergyStore, useUserTapPowerStore } from '@/stores/userAbilities'
import { useUserBalanceStore } from '@/stores/userBalance'
import { useUserRankStore } from '@/stores/userRank'
import { storeToRefs } from 'pinia'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { useGameEventsStore } from '@/stores/gameEvents'
import { usePassiveIncomeStore } from '@/stores/userPassiveIncome'
import { useUserTasksStore } from '@/stores/userTasks'
import { useI18n } from 'vue-i18n'
import BalanceWidget from './common/BalanceWidget.vue'
import BtnPrimary from './common/BtnPrimary.vue'
import Button from './common/Button_old.vue'
import Container from './common/Container.vue'
import DialogModal from './common/DialogModal.vue'
import InfoBanner from './common/InfoBanner.vue'
import InfoPanel from './common/InfoPanel.vue'
import TapWidget from './common/TapWidget.vue'
import IconCheck from './icons/IconCheck.vue'
import IconRightNavLight from './icons/IconRightNavLight.vue'
import PassiveIncomeSection from './PassiveIncomeSection.vue'
import TheHeader from './TheHeader.vue'

const { t } = useI18n()

const props = defineProps<{ user: User; showPassiveIncomeModal?: boolean }>() //User
defineEmits(['open-tournament-tab', 'open-ranking-tab', 'open-event-tab', 'open-games-tab'])

const balanceStore = useUserBalanceStore()
const { getBalance, updateBalance, updateBalanceLocally } = balanceStore
const { balance } = storeToRefs(balanceStore)

const userAbilitiesStore = useUserEnergyStore()
const { getCurrentEnergy } = userAbilitiesStore
const { currentEnergy: energyCurrent, currentEnergyLimit: energyLimit } =
  storeToRefs(userAbilitiesStore)

const { currentTapPower: tapPower } = storeToRefs(useUserTapPowerStore())

const { getCurrentTapPower } = useUserTapPowerStore()

const userRankStore = useUserRankStore()
const { getRanks } = userRankStore

const userStore = useAuthStore()
const { getUser, setUserRankId } = userStore

const { getTasks, getCompletedTaskIds } = useUserTasksStore()
const gameEventsStore = useGameEventsStore()
const {
  isRegistration,
  isEventPhase,
  infoBannerTitle,
  infoBannerTimerDate,
  infoBannerSubtitle,
  infoBannerTimerText
} = storeToRefs(gameEventsStore)
const { getCurrentEvent } = gameEventsStore

const loadingCredits = ref<boolean>(false)
const clickedCredits = ref<number>(0)
const openPassiveIncomeModal = ref<boolean>(false)

const passiveIncomeStore = usePassiveIncomeStore()
const { getUserTotalPassiveIncome } = passiveIncomeStore
const { userTotalPassiveIncome } = storeToRefs(passiveIncomeStore)

let restoreEnergyIntervalId: any

const totalCredits = computed(() => {
  return balance.value + clickedCredits.value
})

const energy = computed(() => {
  return Math.round(energyCurrent.value) + '/' + energyLimit.value
})

watch(totalCredits, (currentValue) => {
  if (userRankStore.nextRank?.minimum_credits <= currentValue) {
    setUserRankId(userRankStore.nextRank?.id)
    getUser()
  }
})

watch(
  () => props.showPassiveIncomeModal,
  (value) => {
    if (value) openPassiveIncomeModal.value = true
  }
)

onMounted(async () => {
  loadingCredits.value = true
  try {
    await getBalance()

    await Promise.all([
      getCurrentEnergy(),
      getCurrentTapPower(),
      getRanks(),
      getTasks(),
      getCompletedTaskIds(),
      getUserTotalPassiveIncome(),
      getCurrentEvent()
    ]).catch(() => {})

    restoreEnergyIntervalId = setInterval(restoreEnergy, 1000)

    increment()
  } catch (error) {
    //
  } finally {
    loadingCredits.value = false
  }
})

onBeforeUnmount(() => {
  if (restoreEnergyIntervalId) {
    clearInterval(restoreEnergyIntervalId)
  }
})

function clickCredits() {
  if (energyCurrent.value - tapPower.value >= 0) {
    energyCurrent.value -= tapPower.value
    clickedCredits.value += tapPower.value
  }
}

async function restoreEnergy() {
  const newEnergy = Math.min(energyCurrent.value + energyLimit.value / 900, energyLimit.value)
  energyCurrent.value = newEnergy
}

async function increment() {
  if (clickedCredits.value > 0) {
    const updateCredits = clickedCredits.value

    await updateBalance(updateCredits)

    clickedCredits.value = Math.max(
      clickedCredits.value - updateCredits,
      updateCredits - clickedCredits.value
    )
  }

  setTimeout(() => {
    increment()
  }, 2000)
}

let passiveIncomeTimeout: string | number | NodeJS.Timeout | null | undefined = null

watch(userTotalPassiveIncome, (currentValue, prevValue) => {
  if (currentValue > prevValue) {
    addPassiveIncome()
  }
})

let passiveIncomeAccumulator: number = 0

function addPassiveIncome() {
  passiveIncomeAccumulator += userTotalPassiveIncome.value / 3600

  if (passiveIncomeAccumulator >= 1) {
    const incomeToAdd = Math.floor(passiveIncomeAccumulator)
    passiveIncomeAccumulator -= incomeToAdd
    updateBalanceLocally(incomeToAdd)
  }

  if (passiveIncomeTimeout) {
    clearTimeout(passiveIncomeTimeout)
  }

  passiveIncomeTimeout = setTimeout(() => {
    addPassiveIncome()
  }, 1000)
}

const passiveIncome = () => {
  openPassiveIncomeModal.value = true
}
const closePassiveIncomeModal = () => {
  openPassiveIncomeModal.value = false
}
</script>

<template>
  <h2 v-if="loadingCredits"></h2>
  <div v-else class="home-body">
    <Container>
      <!-- top menu  -->
      <TheHeader class="mt-5" @showRankHandler="$emit('open-ranking-tab')" />

      <BalanceWidget :balance="totalCredits" class="mt-4" />
    </Container>

    <!-- main image  -->
    <div class="tap-container">
      <TapWidget v-if="true" class="mt-8 mb-5" :tapPower="tapPower" @tap="clickCredits" />
    </div>

    <!-- info  -->
    <Container>
      <!-- shows an energy and a passive income   -->
      <InfoPanel :energy="energy" :passiveIncome="userTotalPassiveIncome" />

      <!-- shows tournaments status  -->
      <InfoBanner
        class="mt-4"
        :timerText="t(infoBannerTimerText)"
        :endDate="infoBannerTimerDate"
        :title="t(infoBannerTitle)"
        :subtitle="t(infoBannerSubtitle)"
        uppercase-title
      >
        <template #actions v-if="isRegistration || isEventPhase">
          <BtnPrimary @click="$emit('open-event-tab')">{{
            isRegistration ? t('homeTab.info.registration') : t('homeTab.info.open')
          }}</BtnPrimary>
        </template>
      </InfoBanner>

      <div class="mt-3 activities-bar">
        <Button :active="false" @click="passiveIncome">
          {{ t('passiveIncome.passiveEarn') }}
          <template #appendIcon>
            <IconCheck v-if="false" class="text-primary" style="width: 14px; height: 14px" />
            <IconRightNavLight v-else style="width: 14px; height: 14px" />
          </template>
        </Button>
        <Button @click="$emit('open-games-tab')">
          {{ t('common.games') }}
          <template #appendIcon>
            <IconCheck v-if="false" class="text-primary" style="width: 14px; height: 14px" />
            <IconRightNavLight v-else style="width: 14px; height: 14px" />
          </template>
        </Button>
        <Button @click="$emit('open-tournament-tab')">
          {{ t('common.tournaments') }}
          <template #appendIcon>
            <IconCheck v-if="false" class="text-primary" style="width: 14px; height: 14px" />
            <IconRightNavLight v-else style="width: 14px; height: 14px" />
          </template>
        </Button>
      </div>
    </Container>

    <DialogModal
      v-if="openPassiveIncomeModal"
      @close="closePassiveIncomeModal"
      :title="t('passiveIncome.passiveEarn')"
      :description="t('passiveIncome.passiveEarnDescription')"
    >
      <div class="passive-income">
        <PassiveIncomeSection @close="closePassiveIncomeModal" />
      </div>
    </DialogModal>
  </div>
</template>

<style scoped>
.home-body {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
}
.tap-container {
  width: 100%;
  margin: 0 auto;
}
.passive-income {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 45px;
}
.activities-bar {
  max-width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;
}
@media (min-width: 380px) {
  .activities-bar {
    gap: 10px;
  }
}
</style>
