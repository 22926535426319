<script setup lang="ts">
import EventTimer from '@/components/common/EventTimer.vue'
import { computed } from 'vue'
interface Props {
  gameTimer: Date | null
}
const props = defineProps<Props>()
const emits = defineEmits(['game-over', 'update:game-timer'])

const time = computed({
  get: () => props.gameTimer,
  set: (val) => emits('update:game-timer', val)
})

const stopGame = () => {
  emits('game-over')
  time.value = null
}
</script>

<template>
  <EventTimer
    v-if="time"
    :end-date="time"
    hide-days
    hide-hours
    hide-letters
    divider-symbol=" : "
    color="white"
    class="game-timer"
    color-class="gradient-text"
    :size="26"
    @finished="stopGame"
  />
  <div class="game-timer text-bold gradient-text" v-else>00:30</div>
</template>
