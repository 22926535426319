<script setup lang="ts">
import { useUserRankStore } from '@/stores/userRank'
import { amountFormat } from '@/utils/amountFormat'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import IconBalance from '../icons/IconBalance.vue'
import IconRefresh from '../icons/IconRefresh.vue'
import Avatar from './Avatar.vue'
import ListItem from './ListItem.vue'
import Loader from './Loader.vue'

const { t } = useI18n()

export type ListUser = {
  id: number
  name: string
  balance?: number
  rank_id?: number
  profile_image_url: string
  profit?: number
}

interface Props {
  users: ListUser[]
  loading?: boolean
  noUsersText?: string
  listTitle?: string
  static?: boolean
  showTitle?: boolean
  showUsersCount?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  users: () => [],
  noUsersText: 'userList.noFriendsYet',
  listTitle: 'userList.title',
  static: false,
  loading: false,
  showTitle: true,
  showUsersCount: true
})
const emits = defineEmits(['refresh'])

const scrollComponent = ref(null)
const scrollRef = ref<any>(null)
const userListRef = ref<any>(null)
const fetchingMore = ref(false)
const page = ref(1)
const perPage = ref(20)
const paginatedUsers = ref<ListUser[]>([])

const usersCount = computed(() => props.users.length || 0)
const lastPage = computed(() => Math.ceil(props.users.length / perPage.value))

const rankStore = useUserRankStore()
const { rankNameById } = rankStore

const refreshUsers = async () => {
  emits('refresh')
}

const handleScroll = () => {
  const element = scrollComponent.value as any

  if (lastPage.value <= page.value) return
  if (element && element.getBoundingClientRect().bottom < window.innerHeight) {
    if (!fetchingMore.value) {
      fetchingMore.value = true
      page.value += 1
      const start = perPage.value * (page.value - 1)
      const end = perPage.value * page.value
      const newUsers = props.users.slice(start, end)

      paginatedUsers.value.push(...newUsers)
      fetchingMore.value = false
    }
  }
}

watch(
  () => props.users,
  () => {
    paginatedUsers.value = props.users.slice(0, perPage.value)
  }
)

onMounted(() => {
  scrollRef.value = userListRef.value?.closest('.overflowOn') || window

  if (scrollRef.value) scrollRef.value.addEventListener('scroll', handleScroll)
  paginatedUsers.value = props.users.slice(0, perPage.value)
})
onUnmounted(() => {
  if (scrollRef.value) scrollRef.value.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <section ref="userListRef">
    <div v-if="showTitle" class="users-list">
      <div class="section-title">{{ t(listTitle) }}</div>
      <div class="flex items-center gap-4">
        <div class="users-count" v-if="showUsersCount">{{ usersCount }}</div>
        <IconRefresh
          v-if="static"
          @click="refreshUsers"
          class="text-black"
          :class="{ rotate: loading }"
          style="width: 40px; height: 40px"
        />
      </div>
    </div>

    <div v-if="loading" class="flex justify-center">
      <Loader />
    </div>
    <template v-else>
      <div v-if="users.length > 0" class="list-container">
        <ListItem
          v-for="(user, index) in paginatedUsers"
          :key="user.id"
          :title="user.name"
          :subtitle="user.rank_id ? rankNameById(user.rank_id) : ''"
          :price="amountFormat(user.balance)"
        >
          <template #prepend>
            <Avatar :avatar="user.profile_image_url" :name="user.name" :size="40" />
          </template>
          <!-- <template #subtitleIcon v-if="$slots.profitIcon && user.profit">
            <slot name="profitIcon" />
          </template> -->
          <template #append>
            <div
              v-if="user.profit || $slots.profitIcon"
              class="text-primary flex items-center gap-1"
            >
              <slot v-if="$slots.profitIcon" name="profitIcon" :index="index" />
              <template v-if="user.profit">
                <IconBalance v-if="!$slots.profitIcon" style="width: 26px; height: 26px" />
                +{{ amountFormat(user.profit) }}
              </template>
            </div>
          </template>
        </ListItem>
      </div>
      <div v-else>
        <ListItem> {{ t(noUsersText) }} </ListItem>
      </div>

      <!-- pagination  -->
      <div v-if="fetchingMore" class="flex justify-center">
        <Loader />
      </div>
      <div class="" ref="scrollComponent"></div>
    </template>
  </section>
</template>

<style scoped>
.users-list {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.users-count {
  color: var(--app-gray);
  font-size: 14px;
}
.rotate {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
