<script setup lang="ts">
import { useGlobalIntervalStore } from '@/stores/globalInterval'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

interface Props {
  endDate: Date | undefined
  size?: number
  color?: string
  red?: boolean
  index?: number
  hideDays?: boolean
  hideHours?: boolean
  hideSeconds?: boolean
  hideLetters?: boolean
  bold?: boolean
  dividerSymbol?: string
  colorClass?: string
  showOnlySecondsLetter?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 20,
  hideDays: false,
  hideHours: false,
  hideSeconds: false,
  hideLetters: false,
  bold: true,
  colorClass: '',
  red: false,
  showOnlySecondsLetter: false
})

const emits = defineEmits(['finished'])

const { t } = useI18n()

const { tick } = storeToRefs(useGlobalIntervalStore())

const timerValue = ref<number>(0)

const styles = computed(() => ({
  'font-size': `${props.size}px`,
  color: props.red || props.colorClass ? '' : props.color ? props.color : '#b1f770',
  'font-weight': props.bold ? 700 : 400
}))

const days = computed(() => {
  return Math.floor(timerValue.value / (3600 * 24))
})

const hours = computed(() => {
  const diff = timerValue.value - days.value * 24 * 3600

  return Math.floor(diff / 3600)
})
const minutes = computed(() => {
  const diff = timerValue.value - days.value * 24 * 3600 - hours.value * 60 * 60

  const mmm = Math.floor(diff / 60)
  // console.log(' mmm: ', mmm)
  return mmm
})
const seconds = computed(() => {
  if (props.hideSeconds) return 0
  const diff =
    timerValue.value - days.value * 24 * 3600 - hours.value * 60 * 60 - minutes.value * 60
  return Math.floor(diff)
})

const setCurrentTimerValue = () => {
  if (timerValue.value <= 0) {
    emits('finished')
    return
  }
  timerValue.value -= 1
}

onMounted(() => {
  timerValue.value = secondsLeft(props.endDate)
})

watch(
  () => props.endDate,
  (val) => {
    if (val) timerValue.value = secondsLeft(props.endDate)
  }
)

watch(tick, (val, oldVal) => {
  if (val != oldVal) setCurrentTimerValue()
})

const addZeroOnStart = (val: number) => {
  const stringVal = String(val)
  return stringVal.padStart(2, '0')
}

const secondsLeft = (time: Date | undefined) => {
  if (!time) return 0

  const timeNow = new Date().getTime()
  const timeStart = new Date(time).getTime()

  if (timeStart <= timeNow) return 0

  return Math.floor((timeStart - timeNow) / 1000) || 0
}
</script>

<template>
  <div class="timer flex justify-center items-centers" :class="colorClass" :style="styles">
    <template v-if="!hideDays">
      <div v-if="days" class="text-bold" :key="`hr-${days}${index ? '-' + index : ''}`">
        {{ days }}
      </div>
      <div v-if="days && !hideLetters">{{ t('common.d') }}</div>
      <div v-if="days" class="timer-divider">{{ dividerSymbol ? dividerSymbol : '' }}</div>
    </template>
    <template v-if="!hideHours">
      <div class="text-bold" :key="`hr-${hours}${index ? '-' + index : ''}`">
        {{ addZeroOnStart(hideDays ? hours + days * 24 : hours) }}
      </div>
      <div v-if="!hideLetters">{{ t('common.h') }}</div>
      <div class="timer-divider">{{ dividerSymbol ? dividerSymbol : '' }}</div>
    </template>
    <div class="text-bold" :key="`min-${minutes}${index ? '-' + index : ''}`">
      {{ addZeroOnStart(minutes) }}
    </div>
    <div v-if="!hideLetters">{{ t('common.m') }}</div>
    <template v-if="!hideSeconds">
      <div class="timer-divider">{{ dividerSymbol ? dividerSymbol : '' }}</div>
      <div class="text-bold" :key="`sec-${seconds}${index ? '-' + index : ''}`">
        {{ addZeroOnStart(seconds) }}
      </div>
      <div v-if="!hideLetters || showOnlySecondsLetter">{{ t('common.s') }}</div>
    </template>
  </div>
</template>

<style scoped>
.timer {
  /* color: var(--red); */
  overflow: hidden;
  line-height: 1;
  font-family: 'Roboto', Courier, monospace;
}
</style>
