export const BULLS_COUNT_ROWS = 4
export const BULLS_COUNT_COLS = 3
export const NEXT_LEVEL_STRIKE = 15

export const BULLS_SHOWING_TIME_LEVEL_1 = 550
export const BULLS_SHOWING_TIME_LEVEL_2 = 350
export const BULLS_SHOWING_TIME_LEVEL_3 = 280

export const GAME_ELEMENT_WIDTH = 110
// export const BULLS_SHOWING_TIME_LEVEL_1 = 660
// export const BULLS_SHOWING_TIME_LEVEL_2 = 330
// export const BULLS_SHOWING_TIME_LEVEL_3 = 220
