<script setup lang="ts">
import { useCatchBullStore } from '@/stores/catchBullGame'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import Container from '../common/Container.vue'
import IconBack from '../icons/IconBack.vue'
import IconHelp from '../icons/IconHelp.vue'
import CatchBullShop from './catchTheBull/catchBullShop.vue'
import { CatchBullGame } from './catchTheBull/catchTheBull'
import { BULLS_SHOWING_TIME_LEVEL_1 } from './catchTheBull/consts'
import Footer from './catchTheBull/footer.vue'
import MultiplyIndicator from './catchTheBull/multiplyIndicator.vue'
import Timer from './catchTheBull/timer.vue'

defineEmits(['close'])

const gameContainer = ref<HTMLElement | null>(null)
const canvas = ref<HTMLCanvasElement | null>(null)
const ctx = ref<any>(null)
const game = ref<CatchBullGame | null>(null)
const previousTimeStamp = ref(0)
const canvasWidth = ref(window.innerWidth)
const canvasHeight = ref(Math.min(canvasWidth.value * 1.25, 440))
const gameTimer = ref<Date | null>(null)
const playingGame = ref(false)
const showShop = ref(false)
const showRules = ref(false)

const catchBullStore = useCatchBullStore()
const { getAvailableBoosts, startGame: initGame, finishGame } = catchBullStore
const {
  triesLeft,
  maxTries,
  strike,
  ticketFragmentsGameCount,
  isBombFreeMode,
  isMultiplyBoostMode,
  isTripleBoostMode,
  isSlowDownMode,
  purchasedTries
} = storeToRefs(catchBullStore)

const animate = (timeStamp: number) => {
  const deltaTime = timeStamp - previousTimeStamp.value
  previousTimeStamp.value = timeStamp
  ctx.value?.clearRect(0, 0, canvasWidth.value, canvasHeight.value)
  game.value?.update(deltaTime)
  game.value?.draw(ctx.value)
  requestAnimationFrame(animate)
}

const init = () => {
  game.value?.draw(ctx.value)
}

onMounted(async () => {
  if (canvas.value) {
    ctx.value = canvas.value.getContext('2d')

    canvas.value.width = canvasWidth.value
    canvas.value.height = canvasHeight.value

    game.value = new CatchBullGame(canvasWidth.value, canvasHeight.value)

    init()
  }
  await getAvailableBoosts()
})

const startGame = async () => {
  if (playingGame.value) return

  if ((triesLeft.value > 0 || purchasedTries.value > 0) && game.value) {
    try {
      // send start params
      await initGame({
        useDoublePoints: isMultiplyBoostMode.value,
        useTriplePoints: isTripleBoostMode.value,
        removeBombs: isBombFreeMode.value,
        useSlowDown: isSlowDownMode.value
      })

      game.value.gameOver = false
      game.value.bullInterval = BULLS_SHOWING_TIME_LEVEL_1
      ticketFragmentsGameCount.value = 0
      purchasedTries.value ? purchasedTries.value-- : triesLeft.value--
      strike.value = 0
      previousTimeStamp.value = 0
      playingGame.value = true

      animate(0)
      const seconds = new Date().getSeconds()
      gameTimer.value = new Date(new Date(Date.now()).setSeconds(seconds + 31))
    } catch (error) {
      //
    }
  }
}

const setGameAsOver = async () => {
  playingGame.value = false
  if (game.value) {
    game.value.gameOver = true
    isBombFreeMode.value = false
    isMultiplyBoostMode.value = false
    isTripleBoostMode.value = false
    isSlowDownMode.value = false
  }

  await finishGame({
    fragmentsCollected: ticketFragmentsGameCount.value
  })
}
</script>

<template>
  <div class="catch-bull">
    <!-- preload images  -->
    <img src="/img/combo/1.webp" id="bull-1" />
    <img src="/img/combo/2.webp" id="bull-2" />
    <img src="/img/combo/3.webp" id="bull-3" />
    <img src="/img/combo/6.webp" id="bull-4" />
    <img src="/img/combo/16.webp" id="bull-5" />
    <img src="/img/games/bomb.png" id="bomb" />
    <img src="/img/games/gate.png" id="gate" />
    <img src="/img/games/smokeExplosion.png" id="explosion" />

    <Container class="catch relative" ref="gameContainer">
      <div class="flex justify-between items-center mt-6">
        <IconBack
          class="text-light-green"
          style="width: 20px; height: 20px"
          @click="$emit('close')"
        />
        <IconHelp class="text-primary" style="width: 26px; height: 26px" />
      </div>

      <img src="/img/preload/ellipse.png" class="ellipse1" />
    </Container>

    <div class="relative flex flex-col h-100" style="z-index: 4">
      <div class="mt-6 mb-4 flex flex-col justify-center items-center">
        <Timer v-model:gameTimer="gameTimer" @game-over="setGameAsOver" />
        <MultiplyIndicator class="multiply-indicator" />
      </div>

      <canvas ref="canvas" id="canvas"></canvas>

      <!-- start btn  -->
      <div v-if="!playingGame" class="absolute start-btn">
        <Container>
          <button @click="startGame" :disabled="playingGame" class="relative">
            <img src="/img/games/start_button.webp" class="ofi" />
            <div class="absolute start-btn-text">
              Start {{ triesLeft }}/{{ maxTries }} {{ purchasedTries ? purchasedTries : '' }}
            </div>
          </button>
        </Container>
      </div>
    </div>

    <!-- footer  -->
    <div class="footer mt-auto">
      <Footer :playingGame="playingGame" @show-shop="showShop = true" />
    </div>

    <img src="/img/preload/ellipse.png" class="ellipse" />

    <CatchBullShop v-if="showShop" @close="showShop = false" />
    <CatchBullRules v-if="showRules" @close="showRules = false" />"
  </div>
</template>

<style scoped>
#bull-1,
#bull-2,
#bull-3,
#bull-4,
#bull-5,
#bomb,
#gate,
#explosion {
  display: none;
}
#canvas {
  position: relative;
  z-index: 5;
}

.game-timer {
  line-height: 1;
  font-family: 'Roboto', Courier, monospace;
  font-size: 26px;
}
.multiply-indicator {
  align-self: stretch;
  max-width: 60%;
  margin: 16px auto 12px;
}
.catch-bull {
  height: 100%;
  background-image: url('/img/games/catchBullBg_v4.png');
  background-position: center top -20px;
  background-repeat: no-repeat;
  background-size: contain;
}
.start-btn {
  inset: 0;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}
.start-btn button {
  width: 100%;
  /* height: 56px; */
  border: none;
  background: none;
}
.start-btn-text {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  inset: 0;
  /* transform: translateY(-50%, -50%); */
  color: var(--black, #0d0e0c);
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer {
  position: fixed;
  z-index: 10;
  bottom: 40px;
  left: 0;
  right: 0;
}
.ellipse,
.ellipse1 {
  position: absolute;
  left: 0;
  right: 0;
}
.ellipse {
  bottom: -30px;
  z-index: 0;
  object-fit: cover;
}
.catch {
  z-index: 3;
}
.ellipse1 {
  z-index: -1;
  top: -122px;
  transform: rotate(180deg);
}
</style>
