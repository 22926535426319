import { useCatchBullStore } from '@/stores/catchBullGame'
import type { BaseBull } from './bulls/bull'
import { Bull1 } from './bulls/bull-1'
import { Bull2 } from './bulls/bull-2'
import { Bull3 } from './bulls/bull-3'
import { Bull4 } from './bulls/bull-4'
import { Bull5 } from './bulls/bull-5'
import { BullBomb } from './bulls/bull-bomb'
import {
  BULLS_COUNT_COLS,
  BULLS_COUNT_ROWS,
  BULLS_SHOWING_TIME_LEVEL_1,
  BULLS_SHOWING_TIME_LEVEL_2,
  BULLS_SHOWING_TIME_LEVEL_3,
  NEXT_LEVEL_STRIKE
} from './consts'
import type { Explosion } from './explosion'
import { Gate } from './gates'
import { InputHandler } from './inputs'
import { SmockExplosion } from './smockeExlosion'

export class CatchBullGame {
  width: number
  height: number

  inputs: InputHandler
  touchCoord: { x: number; y: number } | null

  bulls: BaseBull[]
  bullTimer: number
  bullInterval: number

  gates: Gate[][]

  explosions: Explosion[]

  gameOver: boolean

  store: ReturnType<typeof useCatchBullStore>

  constructor(width: number, height: number) {
    this.width = width
    this.height = height

    this.bulls = []
    this.bullTimer = 0
    this.bullInterval = BULLS_SHOWING_TIME_LEVEL_1

    this.gates = Array.from({ length: 4 }, () => new Array(3)) //[]

    for (let y = 0; y < BULLS_COUNT_ROWS; y++) {
      for (let i = 0; i < BULLS_COUNT_COLS; i++) {
        this.gates[y][i] = new Gate(this, i * ((this.width - 35) / BULLS_COUNT_COLS) + 25, y)
        // console.log('gate: ', this.gates[y][i])
      }
    }
    // console.log('xxxxxxx: ', (this.width - 35 - 110 * BULLS_COUNT_COLS) * 0.5)

    this.explosions = []

    this.inputs = new InputHandler(this)
    this.touchCoord = null

    this.gameOver = false

    this.store = useCatchBullStore()
  }
  update(deltaTime: number) {
    if (!this.gameOver) {
      this.bulls.forEach((bull) => {
        bull.update(deltaTime)
        this.updateBull(bull)
      })

      // add bull
      if (this.bullTimer > this.bullInterval) {
        this.addBull()
        this.bullTimer = 0
      } else this.bullTimer += deltaTime

      this.explosions.forEach((explosion) => {
        explosion.update(deltaTime)
      })
      this.gates.forEach((row) => {
        row.forEach((gate) => gate.update())
      })

      // remove elements
      this.explosions = this.explosions.filter((explosion) => !explosion.needToRemove)
      this.bulls = this.bulls.filter((bull) => !bull.needToRemove)

      if (this.store.strike < NEXT_LEVEL_STRIKE) {
        // level 1
        this.store.ticketsMultiplyCoefficient = this.store.isTripleBoostMode
          ? 3
          : this.store.isMultiplyBoostMode
            ? 2
            : 1

        this.bullInterval = this.store.isSlowDownMode
          ? BULLS_SHOWING_TIME_LEVEL_1 * 1.25
          : BULLS_SHOWING_TIME_LEVEL_1
      } else if (
        this.store.strike >= NEXT_LEVEL_STRIKE &&
        this.store.strike < 2 * NEXT_LEVEL_STRIKE
      ) {
        // level 2
        this.store.ticketsMultiplyCoefficient = this.store.isTripleBoostMode
          ? 6
          : this.store.isMultiplyBoostMode
            ? 4
            : 2

        this.bullInterval = this.store.isSlowDownMode
          ? BULLS_SHOWING_TIME_LEVEL_2 * 1.25
          : BULLS_SHOWING_TIME_LEVEL_2
      } else {
        // max level
        this.store.ticketsMultiplyCoefficient = this.store.isTripleBoostMode
          ? 9
          : this.store.isMultiplyBoostMode
            ? 6
            : 3

        this.bullInterval = this.store.isSlowDownMode
          ? BULLS_SHOWING_TIME_LEVEL_3 * 1.25
          : BULLS_SHOWING_TIME_LEVEL_3
      }
    }
  }
  draw(ctx: CanvasRenderingContext2D) {
    // this.background.draw(ctx)
    this.bulls.forEach((bull) => bull.draw(ctx))
    this.explosions.forEach((explosion) => explosion.draw(ctx))
    this.gates.forEach((row) => {
      row.forEach((gate) => gate.draw(ctx))
    })
  }
  addBull() {
    const randomRow = Math.floor(Math.random() * BULLS_COUNT_ROWS)
    const randomCol = Math.floor(Math.random() * BULLS_COUNT_COLS)
    // const randomBull = Math.ceil(Math.random() * BULLS_COUNT)
    const randomBull = Math.random()

    const x = randomCol * ((this.width - 35) / BULLS_COUNT_COLS) + 25

    this.gates[randomRow][randomCol].isOpen = true

    if (randomBull < 0.18) {
      this.bulls.push(new Bull1(this, x, randomRow, randomCol, this.bullInterval))
    } else if (randomBull >= 0.18 && randomBull < 0.36) {
      this.bulls.push(new Bull2(this, x, randomRow, randomCol, this.bullInterval))
    } else if (randomBull >= 0.36 && randomBull < 0.54) {
      this.bulls.push(new Bull3(this, x, randomRow, randomCol, this.bullInterval))
    } else if (randomBull >= 0.54 && randomBull < 0.72) {
      this.bulls.push(new Bull4(this, x, randomRow, randomCol, this.bullInterval))
    } else if (randomBull >= 0.72 && randomBull < 0.9) {
      this.bulls.push(new Bull5(this, x, randomRow, randomCol, this.bullInterval))
    } else if (this.store.isBombFreeMode) {
      this.bulls.push(new Bull5(this, x, randomRow, randomCol, this.bullInterval))
    } else {
      this.bulls.push(new BullBomb(this, x, randomRow, randomCol, this.bullInterval))
    }
  }
  updateBull(bull: BaseBull) {
    if (!bull.needToRemove && !!this.touchCoord && !bull.checkingCollision) {
      const isCollision = this.collision(bull, this.touchCoord)
      bull.checkingCollision = true
      if (isCollision) {
        console.log('isCollision: ')
        if (bull.isBomb) {
          console.log('bomb: ')
          this.addExplosion(bull)
          this.store.ticketFragmentsGameCount -= 5
          this.store.ticketFragmentsTotalCount -= 5
          if (this.store.ticketFragmentsGameCount < 0) this.store.ticketFragmentsGameCount = 0
          if (this.store.ticketFragmentsTotalCount < 0) this.store.ticketFragmentsTotalCount = 0
          // this.store.strike = 0
        } else {
          this.store.ticketFragmentsGameCount += 1 * this.store.ticketsMultiplyCoefficient
          this.store.ticketFragmentsTotalCount += 1 * this.store.ticketsMultiplyCoefficient
          this.store.strike += 1
        }
        bull.needToRemove = true
        this.gates[bull.row][bull.col].isOpen = false
      } else {
        console.log('miss: ')
        // this.store.strike = 0
      }
    }
    if (!this.touchCoord) {
      bull.checkingCollision = false
    }
  }
  addExplosion(bull: BaseBull) {
    this.explosions.push(
      new SmockExplosion(this, bull.x + bull.width * 0.5, bull.y + bull.height * 0.5)
    )
  }
  collision(object: any, touch: any) {
    // console.log('touch x: ', touch.x)
    // console.log('object x: ', object.x, ' - ', object.x + object.width)
    // console.log('touch y: ', touch.y)
    // console.log('object y: ', object.y, ' - ', object.y + object.height)

    return (
      touch.x > object.x &&
      touch.x < object.x + object.width &&
      touch.y > object.y &&
      touch.y < object.y + object.height
    )
  }
}
