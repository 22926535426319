<script setup lang="ts">
import { getUserPassiveIncomeEarned } from '@/application/services/useApi'
import useTelegram from '@/application/services/useTelegram'
import { useAuthStore } from '@/stores/auth'
import { useGlobalIntervalStore } from '@/stores/globalInterval'
import { amountFormatWithSymbol } from '@/utils/amountFormat'
import {
  TAB_BOOSTS,
  TAB_CLAIM,
  TAB_EARNS,
  TAB_EVENT,
  TAB_GAMES,
  TAB_HOME,
  TAB_INVITE,
  TAB_RAFFLE,
  TAB_RANKING,
  TAB_TOURNAMENT
} from '@/utils/tabs'
import { storeToRefs } from 'pinia'
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import DialogModal from './common/DialogModal.vue'
import EventTab from './EventTab.vue'
import HomeTab from './HomeTab.vue'

import { usePassiveIncomeStore } from '@/stores/userPassiveIncome'
import BoostsTab from './BoostsTab.vue'
import ClaimTab from './ClaimTab.vue'
import BtnPrimary from './common/BtnPrimary.vue'
import EarnsTab from './EarnsTab.vue'
import GamesTab from './GamesTab.vue'
import IconBalance from './icons/IconBalance.vue'
import InviteTab from './InviteTab.vue'
import RaffleTab from './RaffleTab.vue'
import RankingTab from './Ranking.vue'
import TheMenuBar from './TheMenuBar.vue'
import TournamentTab from './TournamentTab.vue'

const { t } = useI18n()

const { user } = storeToRefs(useAuthStore())

const { showBackButton, hideBackButton } = useTelegram()

const globalIntervalStore = useGlobalIntervalStore()
const { startInterval, stopInterval } = globalIntervalStore
const { showMenuBar } = storeToRefs(globalIntervalStore)

const { userTotalPassiveIncome } = storeToRefs(usePassiveIncomeStore())

const slotContainer = ref(null)
const currentTab = ref(TAB_HOME)
const passiveEarningsWhileOffline = ref(0)
const openPassiveEarningsModal = ref(false)
const showPassiveIncomeModal = ref(false)
const passiveEarningsModalWasShowed = ref(false)
const userPassiveIncomePercentToAverageIncomePerUser = ref(0)

onMounted(async () => {
  localStorage.setItem('visited', 'true')
  startInterval()
})
onBeforeUnmount(() => {
  stopInterval()
})

watch(currentTab, () => {
  if (currentTab.value !== TAB_HOME) {
    showBackButton(() => {
      currentTab.value = TAB_HOME
    })
  } else {
    hideBackButton()
  }
})

watch(user, (val, oldVal) => {
  if (val && val.id != oldVal?.id) {
    if (!passiveEarningsModalWasShowed.value) {
      getUserPassiveIncomeEarned().then((result) => {
        if (result || result == 0) {
          openPassiveEarningsModal.value = true
          passiveEarningsWhileOffline.value = result.passiveEarningsWhileOffline
          userPassiveIncomePercentToAverageIncomePerUser.value =
            result.userPassiveIncomePercentToAverageIncomePerUser
          passiveEarningsModalWasShowed.value = true
          setTimeout(() => {
            // openPassiveEarningsModal.value = false
          }, 5000)
        }
      })
    }
  }
})

const upgrade = () => {
  openPassiveEarningsModal.value = false
  nextTick(() => {
    showPassiveIncomeModal.value = true
  })
}
// const setCurrentTab = (title: string) => {
//   currentTab.value = title
// }
</script>

<template>
  <h1 v-if="!user?.name"></h1>
  <div v-else class="overflowOn" ref="slotContainer">
    <!-- <transition name="fade" mode="out-in"> -->
    <KeepAlive>
      <HomeTab
        :user="user"
        v-if="currentTab === TAB_HOME"
        @open-tournament-tab="currentTab = TAB_TOURNAMENT"
        @open-event-tab="currentTab = TAB_EVENT"
        @open-games-tab="currentTab = TAB_GAMES"
        @open-ranking-tab="currentTab = TAB_RANKING"
        :showPassiveIncomeModal="showPassiveIncomeModal"
      />
    </KeepAlive>
    <InviteTab v-if="currentTab === TAB_INVITE" :scrollRef="slotContainer" />
    <BoostsTab v-else-if="currentTab === TAB_BOOSTS" />
    <EarnsTab v-else-if="currentTab === TAB_EARNS" />
    <ClaimTab v-else-if="currentTab === TAB_CLAIM" />
    <RaffleTab v-else-if="currentTab === TAB_RAFFLE" />
    <EventTab v-else-if="currentTab === TAB_EVENT" />
    <TournamentTab v-else-if="currentTab === TAB_TOURNAMENT" />
    <RankingTab v-if="currentTab === TAB_RANKING" />
    <GamesTab v-if="currentTab === TAB_GAMES" @close="currentTab = TAB_HOME" />

    <!-- </transition> -->

    <!-- main menu  -->
    <!-- <TheMenuBar @set-tab="setCurrentTab($event)" /> -->
    <TheMenuBar v-if="showMenuBar" v-model:currentTab="currentTab" />

    <!-- earnings while offline  -->
    <DialogModal v-if="openPassiveEarningsModal" @close="openPassiveEarningsModal = false">
      <div class="text-center mt-2">
        <div class="earning-offline-title">{{ t('passiveIncome.earnedWhileOffline') }}</div>
        <div class="mt-4 mb-4 flex justify-center items-center gap-2">
          <IconBalance style="width: 52px; height: 52px" />
          <div class="earning-offline-value">
            {{ amountFormatWithSymbol(passiveEarningsWhileOffline) }}
          </div>
        </div>
        <div
          v-if="userTotalPassiveIncome !== 0"
          class="earning-offline-title"
          v-html="
            t('passiveIncome.passiveIncomePercentToAverageIncomePerUser', {
              aboveOrBelow:
                userPassiveIncomePercentToAverageIncomePerUser > 0
                  ? t('common.above')
                  : t('common.below'),
              percent: userPassiveIncomePercentToAverageIncomePerUser,
              classes:
                userPassiveIncomePercentToAverageIncomePerUser > 0
                  ? `class='text-primary text-bold'`
                  : `class='text-red text-bold'`
            })
          "
        ></div>
        <div v-else class="earning-offline-title text-red">
          {{ t('passiveIncome.passiveIncomeIsZero') }}
        </div>
        <div class="flex justify-stretch"></div>
        <BtnPrimary @click="upgrade" block big>
          {{ t('passiveIncome.upgrade') }}
        </BtnPrimary>
      </div>
    </DialogModal>
  </div>
</template>

<style scoped>
.earning-offline-title {
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
  margin-bottom: 48px;
}
.earning-offline-value {
  color: var(--white, #f3ffe9);
  font-family: Urbanist;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.22px;
}
.text-red {
  color: var(--red, #fe5258);
}
</style>
